import React, { useState } from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import base_url from '../../api/api';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';



export default function Addplan() {
    const navigate = useNavigate()
    const [user, setUser] = useState({
        plan_title: '',
        validity: '',
        paid_price: '',
        plan_data: '',
    })

    const jwt_token = localStorage.getItem('token')
    // console.log(usrProfile)
    function submit(e) {
        e.preventDefault();
        axios.post(base_url + 'plans/create_plan', {
            plan_name: user.plan_title,
            validity: user.validity,
            paid_price: user.paid_price,
            paid_data: user.plan_data,
        }, {
            headers: {
                jwt: jwt_token
            }
        })
            .then(res => {
                // console.log(res)
                if (res.data.status === true) {
                    // navigate("/user_details");
                    window.location.reload()
                } else if (res.data.code === 1001) {
                    localStorage.clear();
                    navigate("/login");
                } else {
                    if (res.data.message === "plan_name cannot be empty") {
                        swal('Enter Plan title', {
                            icon: 'warning',
                            button: true
                        })
                    } else if (res.data.message === "validity cannot be empty"){
                        swal('Enter Plan Validity', {
                            icon: 'warning',
                            button: true
                        })
                    } else if (res.data.message === "paid_price cannot be empty"){
                        swal('Enter Price', {
                            icon: 'warning',
                            button: true
                        })
                    } else if (res.data.message === "paid_data cannot be empty"){
                        swal('Enter Plan data', {
                            icon: 'warning',
                            button: true
                        })
                    }
                }
                // <p>{errMsg}</p>
            })
    }

    function User(e) {
        const newuser = { ...user }
        newuser[e.target.id] = e.target.value
        setUser(newuser)
        // console.log(newuser)
    }
    return (
        <>
            <form onSubmit={(e) => submit(e)}>
                <Row>
                    <Col lg={4}>
                        <label>Plan Title</label>
                        <input placeholder='Plan Name' autocomplete="off" onChange={(e) => User(e)} id="plan_title" value={user.plan_title} className='form-control mb-3' type='text'></input>
                    </Col>
                    <Col lg={4}>
                        <label>Validity (in days)</label>
                        <input placeholder='Enter Validity' autocomplete="off" onChange={(e) => User(e)} id="validity" value={user.validity} className='form-control mb-3' type='number'></input>
                    </Col>
                    <Col lg={4}>
                        <label>Price ($) </label>
                        <input placeholder='$' autocomplete="off" onChange={(e) => User(e)} id="paid_price" value={user.paid_price} className='form-control mb-3' type='number'></input>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <label>Data</label>
                        <input placeholder='Data' autocomplete="off" onChange={(e) => User(e)} id="plan_data" value={user.plan_data} className='form-control mb-3' type='text'></input>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <button type='submit' className='log_btn btn btn-success'>Submit</button>
                    </Col>
                </Row>
            </form>
        </>
    )
}
