import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Container, Button, Accordion } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Sidebar from "../Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";
import AddNewUser from "./AddNewUser";
import swal from 'sweetalert';
import base_url from "../../api/api";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
var totalrow = '';

const UserTable = () => {
  // debugger;
  const navigate = useNavigate()
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getUserWithFetch();
  }, []);

  const getUserWithFetch = async () => {
    axios.get(base_url + 'users/get_users',{
      headers :{
        jwt:jwt_token
      }
    }).then(res=>{
      // console.log(res)
      if(res.data.status){
        setUserData(res.data.data.reverse());
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      }
    })
    // console.log(response)
  };
  
  const jwt_token = localStorage.getItem('token')
  const hendleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setUserData(userData.filter((item) => item.id !== id))
          axios.post(base_url + 'users/delete_user', {
            user_id: id
          }, {
            headers: {
              'Content-Type': 'application/json',
              jwt: jwt_token
            }
          }).then(response => {
            if (response.status) {
              swal("User deleted sucessfully!", {
                icon: "success",
                buttons: false,
                timer: 2000
              })
            } else if (response.data.code === 1001) {
              localStorage.clear();
              navigate("/login");
            } else {
              swal("Something went wrong!", {
                icon: "warning",
                buttons: false,
                timer: 2000
              })
            }
          });

        }

      });
  }



  const UpdateUserStatus = async (id, status) => {
    axios.post(base_url + 'users/enable_disable_user', {
      user_id: id,
      status
    },
      {
        headers: {
          'Content-Type': 'application/json',
          jwt: jwt_token
        }
    }).then(res => {
      if (res.data.status === true) {
        swal('User Status Update Successfully!', {
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(window.location.reload())
        // navigate("/user_details");
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      }
    })
    // alert(id)
  }

  const active = useSelector((state) => state.toggleSidebar.active);

  const actions_btn = (cell, row, rowIndex, formatExtraData) => {
    if (user.is_admin === '1') {
      return (
        <div>

          {edit_data ?
            <Link to={"/edit_user/" + row.id}>
              <EditIcon />
            </Link>
            : ''
          }
          <Link to={'/user/' + row.id}>
            <VisibilityIcon />
          </Link >
          {
            delete_data ?
              <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} />
              :
              ''
          }


        </div>
      );
    } else {
      return (
        <div>
          <Link to={"/edit_user/" + row.id}>
            <EditIcon />
          </Link>
          <Link to={'/user/' + row.id}>
            <VisibilityIcon />
          </Link >
          <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} />
        </div>
      );
    }
  };
  const StatusButton = (cell, row, rowIndex) => {
    let userStatus = "0";
    if (user.is_admin === '1') {
      if (row.status === '0') {
        userStatus = "1";
        return (
          <div className='usr_stats'>
            {status_data ? <Button className="disabl" onClick={() => UpdateUserStatus(row.id, userStatus)}>Disabled</Button> : <p className="mb-0">Disabled</p>}
          </div>
        )
      }

      else if (row.status === '1') {
        return (
          <div className='usr_stats'>
            {status_data ? <Button className="activ" onClick={() => UpdateUserStatus(row.id, userStatus)}>Active</Button> : <p className="mb-0">Active</p>}
          </div>
        )
      }
    }
    else {
      if (row.status === '0') {
        userStatus = "1";
        return (
          <div className='usr_stats'>
            <Button className="disabl" onClick={() => UpdateUserStatus(row.id, userStatus)}>Disabled</Button>
          </div>
        )
      }

      else if (row.status === '1') {
        return (
          <div className='usr_stats'>
            <Button className="activ" onClick={() => UpdateUserStatus(row.id, userStatus)}>Active</Button>
          </div>
        )
      }
    }
  }
  const AccountType = (cell, row, rowIndex) => {
    if (row.user_type === '0') {
      return (
        <span>Trial </span>
      )
    } else if (row.user_type === '1') {
      return (
        <span>Free</span>
      )
    } else if (row.user_type === '2') {
      return (
        <span>Premium</span>
      )
    }
  }
  const StatusDropdown = {
    0: 'Disable',
    1: 'Active'
  };
  const AccountDropdown = {
    0: 'Trial',
    1: 'Free',
    2: 'Premium'
  };
  const columns = [
    {
      text: 'S.no',
      formatter: (cell, row, rowIndex) =>rowIndex + 1
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      filter: textFilter()
    },
    {
      dataField: 'mobile',
      sort: true,
      text: 'Mobile',
      filter: textFilter()
    },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      filter: selectFilter({
        options: StatusDropdown
      }),
      formatter: StatusButton
    },
    {
      dataField: 'user_type',
      sort: true,
      text: 'Account Type',
      filter: selectFilter({
        options: AccountDropdown
      }),
      formatter: AccountType
    },
    {
      dataField: 'creation_time',
      sort: true,
      text: 'Creation Time',
      formatter: (cell, row) => {
        let dateObj = cell;
        if (typeof cell !== 'object') {
          dateObj = row.creation_time;
        }
        return `${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dateObj)}`;
      },
    },
    {
      text: 'Action',
      formatter: actions_btn
    }
  ];


  // debugger
  const customTotal = (from, to, size) => {
    totalrow = to
    return (
      <span className="react-bootstrap-table-pagination-total" >
        Showing {from} to {to} of {size} Results
      </span >
    )
  };
  // console.log(totalrow)
  const pagination = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange(current) {
      setPage(current);
    },
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }],

  };
  const user_role = JSON.parse(localStorage.getItem('user_role'));
  const user = JSON.parse(localStorage.getItem('user'));
  let add_count = 0;
  let add_data = '';
  let edit_count = 0;
  let edit_data = false;
  let delete_count = 0;
  let delete_data = false;
  let status_count = 0;
  let status_data = false;

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '1') {
        return (
          add_count++
        )
      }
    }, [])
    if (add_count > 0) {
      add_data = <Accordion defaultActiveKey="0" className="mb-4 mt-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>ADD User</Accordion.Header>
          <Accordion.Body>
            <AddNewUser />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    }

  } else {
    add_data = <Accordion defaultActiveKey="0" className="mb-4 mt-2">
      <Accordion.Item eventKey="1">
        <Accordion.Header>ADD User</Accordion.Header>
        <Accordion.Body>
          <AddNewUser />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  }
  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '2') {
        return (
          edit_count++
        )
      }
    }, [])
    if (edit_count > 0) {
      edit_data = true
    }

  }

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '3') {
        return (
          delete_count++
        )
      }
    }, [])
    if (delete_count > 0) {
      delete_data = true
    }

  }

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '4') {
        return (
          status_count++
        )
      }
    }, [])
    if (status_count > 0) {
      status_data = true
    }

  }

  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container fluid className="mb-5">
          {
            add_data
          }
          <h4 className='titl'>User List </h4>
          {/* <DataTable highlightOnHover responsive filter={true} columns={columns} data={userData} pagination={Number} fixedHeader fixedHeaderScrollHeight="450" /> */}
          <BootstrapTable options={{ noDataText: 'There is no data to display' }} pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={userData} columns={columns} filter={filterFactory()} />
        </Container>
      </div>
    </>
  )
}
export default UserTable;