import React from 'react'
import { IoMdBookmark, IoMdCall, IoMdChatboxes, IoMdHammer, IoMdHome, IoMdImage, IoMdPerson } from 'react-icons/io';
import { AiFillDashboard, AiTwotoneBell } from 'react-icons/ai';
import { BsCardList } from 'react-icons/bs';
import { FaPaperPlane } from 'react-icons/fa';
import { GoReport } from 'react-icons/go';
import { FiHelpCircle } from 'react-icons/fi';
import { RiPagesLine, RiPagesFill } from 'react-icons/ri';
import { MdGroups, MdOutlinePlaylistAdd } from 'react-icons/md';
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
import './Header.css'
import { SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import swal from 'sweetalert';

const Sidebar = () => {
    const navigate = useNavigate();
    const active = useSelector((state) => state.toggleSidebar.active);
    const user_role = JSON.parse(localStorage.getItem('user_role'));
    const user = JSON.parse(localStorage.getItem('user'));
    let usr_count = 0;
    let user_data = '';
    let grp_count = 0;
    let group_data = '';
    let plan_count = 0;
    let plan_data = '';
    let notification_count = 0;
    let notification_data = '';
    let transaction_count = 0;
    let transaction_data = '';
    if (user.is_admin === '1') {
        if (user_role !== '') {
            user_role?.map(menu_text => {
                if (menu_text.value === '1' || menu_text.value === '2' || menu_text.value === '3' || menu_text.value === '4') {
                    return (
                        usr_count++
                    )
                }
            }, [])
            if (usr_count > 0) {
                user_data = <> <Link to='/user_details'><IoMdPerson className='icon' /><span>User</span></Link></>;
            }
            user_role?.map(menu_text => {
                if (menu_text.value === '5' || menu_text.value === '6' || menu_text.value === '7' || menu_text.value === '8' || menu_text.value === '9' || menu_text.value === '10' || menu_text.value === '11' || menu_text.value === '12') {
                    return (
                        grp_count++
                    )
                }
            }, [])
            if (grp_count > 0) {
                group_data = <><Link to='/group_list'><BsCardList className='icon' /><span>Group</span></Link></>;
            }
            user_role?.map(menu_text => {
                if (menu_text.value === '13' || menu_text.value === '14' || menu_text.value === '15') {
                    return (
                        plan_count++
                    )
                }
            }, [])
            if (plan_count > 0) {
                plan_data = <><Link to='/plans'><FaPaperPlane className='icon' /><span>Plans</span></Link></>;
            }
            user_role?.map(menu_text => {
                if (menu_text.value === '16') {
                    return (
                        notification_count++
                    )
                }
            }, [])
            if (notification_count > 0) {
                notification_data = <><Link to='/push'><AiTwotoneBell className='icon' /><span>Push Notification</span></Link></>;
            }
            user_role?.map(menu_text => {
                if (menu_text.value === '17' || menu_text.value === '18') {
                    return (
                        transaction_count++
                    )
                }
            }, [])
            if (transaction_count > 0) {
                transaction_data = <><Link to='/transaction'><IoMdChatboxes className='icon' /><span>Transaction</span></Link></>;
            }
        } else {
            return (
                <>
                    <Header />
                    <div className={active ? 'header' : 'header-mobile'}>
                        <nav>
                            <ul className={active ? 'ul-item ' : 'ul-item oicon'}>
                                <li>
                                    <Link to='/dashboard'><AiFillDashboard className='icon' /><span>Dashboard</span></Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </>
            )
        }
    }


    return (
        <>
            <Header />
            <div className={active ? 'header' : 'header-mobile'}>
                <nav>
                    <ul className={active ? 'ul-item ' : 'ul-item oicon'}>
                        {
                            user.is_admin === '0' ? <div>
                                <li>
                                    <Link to='/dashboard'><AiFillDashboard className='icon' /><span>Dashboard</span></Link>
                                </li>
                                <li>
                                    <Link to='/user_details'><IoMdPerson className='icon' /><span>Users</span></Link>
                                </li>
                                <li>
                                    <Link to='/group_list'><BsCardList className='icon' /><span>Groups</span></Link>
                                </li>
                                <li>
                                    <Link to='/plans'><FaPaperPlane className='icon' /><span>Plans</span></Link>
                                </li>
                                <li>
                                    <Link to='/push'><AiTwotoneBell className='icon' /><span>Push Notification</span></Link>
                                </li>
                                <li>
                                    <Link to='/transaction'><IoMdChatboxes className='icon' /><span>Transaction</span></Link>
                                </li>
                                <SubMenu title="Static Page" icon={<RiPagesLine className='icon' />}>
                                    <Link to='/about'><RiPagesFill className='icon' /><span>About Us</span></Link>
                                    <Link to='/termscondition'><RiPagesFill className='icon' /><span>Terms and condition</span></Link>
                                    <Link to='/privacypolicy'><RiPagesFill className='icon' /><span>Privacy Policy</span></Link>
                                </SubMenu>
                                <li>
                                    <Link to='/helpdesk'><FiHelpCircle className='icon' /><span>Help Desk</span></Link>
                                </li>
                                <SubMenu title="Configuration" icon={<GoReport className='icon' />}>
                                    <SubMenu title="Backend Users" icon={<GoReport className='icon' />}>
                                        <Link to='/creatbacknduser'><FiHelpCircle className='icon' /><span>Add new</span></Link>
                                        <Link to='/adminlist'><FiHelpCircle className='icon' /><span>View List</span></Link>
                                    </SubMenu>
                                    <Link to='/rolemanagement'><FiHelpCircle className='icon' /><span>Role management</span></Link>
                                </SubMenu>
                            </div> : <div>
                                <li>
                                    <Link to='/dashboard'><AiFillDashboard className='icon' /><span>Dashboard</span></Link>
                                </li>
                                <li>
                                    {user_data}
                                </li>
                                <li>
                                    {group_data}
                                </li>
                                <li>
                                    {plan_data}
                                </li>
                                <li>
                                    {notification_data}
                                </li>
                                <li>
                                    {transaction_data}
                                </li>
                                {
                                    user.is_admin === 0 ?
                                        <SubMenu title="Static Page" icon={<RiPagesLine className='icon' />}>
                                            <Link to='/about'><RiPagesFill className='icon' /><span>About Us</span></Link>
                                            <Link to='/termscondition'><RiPagesFill className='icon' /><span>Terms and condition</span></Link>
                                            <Link to='/privacypolicy'><RiPagesFill className='icon' /><span>Privacy Policy</span></Link>
                                        </SubMenu> :
                                        ''
                                }
                            </div>
                        }
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Sidebar