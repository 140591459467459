import React, { useState, useEffect } from 'react'
import Sidebar from "./Sidebar";
import { Container, Form, Button, Row, Col,Accordion } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BsTags } from 'react-icons/bs'
import swal from 'sweetalert';
import { BiRupee } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom'
import { FaDashcube } from 'react-icons/fa'
import base_url from "./../api/api";

import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

var totalrow = '';

const Helpdesk = () => {

    const [userData, setUserData] = useState([]);
    const [page, setPage] = useState(1);
    useEffect(() => {
      getUserWithFetch();
    }, []);
    const navigate = useNavigate()
    const jwt_token = localStorage.getItem('token')
    const getUserWithFetch = async () => {
      axios.get(`${base_url}contact/get_contactus_details`, {
        headers: {
          jwt: jwt_token
        }
      }).then(res => {
        // console.log(res)
        if (res.data.status === true) {
          // navigate("/user_details");
          //   window.location.reload()
          setUserData(res.data.data)
        } else if (res.data.code === 1001) {
          localStorage.clear();
          navigate("/login");
        } else {
          alert(res.data.plan_name)
          setUserData(res.data.plan_name)
        }
      })
  
    }
    
    const StatusButton = (cell, row, rowIndex) => {
      if (row.status == 0) {
        return (
          <span>Not found</span>
        )
      } else if (row.status == 1) {
        return (
          <span className='complet'>Complete</span>
        )
      } else if (row.status == 2) {
        return (
          <span className='pending'>Pending</span>
        )
      }
    }
    const hendleDelete = async (id) => {
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            setUserData(userData.filter((item) => item.id !== id))
            axios.get(base_url + 'contact/delete_message_by_id/' + id,{
              headers: {
                'Content-Type': 'application/json',
                jwt: jwt_token
              }
            }).then(response => {
              if (response.status) {
                swal("Meassage deleted sucessfully!", {
                  icon: "success",
                  buttons: false,
                  timer: 2000
                })
              } else if (response.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
              } else {
                swal("Something went wrong!", {
                  icon: "warning",
                  buttons: false,
                  timer: 2000
                })
              }
            });
  
          }
  
        });
    }
    const UpdateUserStatus = async (id, status) => {
      axios.post(base_url + 'contact/close_query_by_id', {
      query_id: id,
      status
    },{
      headers:{
        jwt : jwt_token
      }
    }).then(res => {
      if (res.data.status === true) {
        swal('User query Update Successfully!', {
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(window.location.reload())
        // navigate("/user_details");
      } 
    })
    // alert(id)

  }
    const ReplyStatusButton = (cell, row, rowIndex) => {
      let status = "1";
      if (row.status === '0') {
        return (
          <div className='usr_stats'>
            <span className='text-danger'>Query Closed</span>
          </div>
        )
      }

      else if (row.status === '1') {
        status = "0";
        return (
          <div className='usr_stats'>
            <Button className="activ" onClick={() => UpdateUserStatus(row.id, status)}>Open</Button>
          </div>
        )
      }
    }
    const Viewbtn = (cell, row, rowIndex) => {
      return(
        <div>
          <Link to={'/helpdeskreply/' + row.id}>
          {/* <VisibilityIcon /> */}
           <button className='btn btn-danger view_btn'>Reply</button>
          </Link >
          <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} />
        </div>
      )
    }
  
    const StatusDropdown = {
      0: 'Not Found',
      1: 'Complete',
      2: 'Pending'
    };
  
    const columns = [
      {
        text: 'S.no.',
        formatter: (cell, row, rowIndex) => rowIndex + 1
      },
      {
        dataField: 'full_name',
        sort: true,
        text: 'Name',
        filter: textFilter()
      },
      {
        dataField: 'email',
        sort: true,
        text: 'Email',
        filter: textFilter()
      },
      {
        dataField: 'mobile',
        sort: true,
        text: 'Mobile',
        filter: textFilter()
      },
      {
        text: 'Message',
        dataField:'message',
        sort: true,
        filter: textFilter(),
        classes:'single_line_msg'
      },
      {
        dataField: 'creation_time',
        sort: true,
        text: 'Creation Time',
        formatter: (cell, row) => {
          let dateObj = cell;
          if (typeof cell !== 'object') {
            dateObj = row.creation_time;
          }
          return `${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format((dateObj * 1000))}`;
        },
      },
      {
        text: 'Status',
        formatter: ReplyStatusButton
      },
      {
        text: 'Action',
        formatter: Viewbtn
      }
    ];
  
  
    // debugger
    const customTotal = (from, to, size) => {
      totalrow = to
      return (
        <span className="react-bootstrap-table-pagination-total" >
          Showing {from} to {to} of {size} Results
        </span >
      )
    };
    // console.log(totalrow)
    const pagination = {
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Previous',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      onPageChange(current) {
        setPage(current);
      },
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      },],
  
    };

    const active = useSelector((state) => state.toggleSidebar.active);
    return(

        <>
        <Sidebar />
        <div className={active ? 'content' : 'content-mobile'}>
          <Container>     
            <div className='tr_tab mt-3 mb-3'>
              <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={userData} columns={columns} filter={filterFactory()} />
            </div>
          </Container>
        </div>
      </>
  
    )
}

export default Helpdesk;