import React, { useEffect, useState } from 'react'
import Sidebar from "./Sidebar";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from 'axios'
import base_url from '../api/api';
import { useNavigate } from "react-router-dom";
import S3FileUpload from 'react-s3';
import { validate } from 'validate.js';
import swal from 'sweetalert';
import { Input } from '@mui/icons-material';
import Loader from "react-js-loader";
import Progressbar from 'react-js-progressbar';
// import ReactHtmlParser from 'react-html-parser';
const config = {
    bucketName: 'photobomb-1',
    dirName: 'profile_images', /* optional */
    region: 'us-east-1',
    accessKeyId: 'AKIA2XVW5RN2BOZPCDPG',
    secretAccessKey: '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',
}
const Profile = () => {
    const [percentage, setPercentage] = useState(0);

    const navigate = useNavigate();
    const admin_id = JSON.parse(localStorage.getItem('user'));

    const [usrProfile, setUsrProfile] = useState({
        profile_picture: ''
    })
    const [pssupdat, setPssupdat] = useState({
        user_id: '',
        new_password: '',
        password: '',
        confirm_password: ''
    });
    const [adminDetail, setAdminDetail] = useState({})
    const [errors, setErrors] = useState({
        user_id: '',
        new_password: '',
        password: '',
        confirm_password: ''

    })
    const [errorsto, setErrorsto] = useState({
        user_id: '',
        name: '',
        email: '',
        mobile: '',
        
    })
    function SubmitUpdatedProfile(e) {
        e.preventDefault();
        let validationErrors = {};
        Object.keys(pssupdat).forEach(name => {
            const error = validate(name, pssupdat[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        if (pssupdat.new_password === pssupdat.confirm_password) {
            axios.post(base_url + 'users/change_password', {
                user_id: admin_id.id,
                new_password: pssupdat.new_password,
                password: pssupdat.password
            } ,{
                headers: {
                    jwt: jwt_token
                },
            }).then(res => {
                    // console.log(res)
                    if (res.data.status) {
                        // console.log(res)
                        swal(res.data.message, {
                            icon: "success",
                            buttons: false,
                            timer: 2000
                        }).then(
                            localStorage.clear(),
                            navigate("/login")
                        )
                    } else if (res.data.code === 1001) {
                        localStorage.clear();
                        navigate("/login");
                    } else if(res.data.message === "Please enter correct old password") {
                        swal("Entered current password is wrong", {
                            icon: "warning",
                            button: true,
                        })
                    }
                });
        } else {
            swal('New Password and Confirm Password not match', {
                icon: 'warning',
                button: true,
            })
        }
    }
    const jwt_token = localStorage.getItem('token');
    const logged_admin = JSON.parse(localStorage.getItem('user'));
    function SubmitUpdatedProfileto(e) {debugger
        e.preventDefault();
        axios.post(base_url + 'users/update_admin_profile', {
            admin_id: admin_id.id,
            name: adminDetail.username,
            email: adminDetail.email,
            mobile: adminDetail.mobile,
            country_code: adminDetail.country_code

        }, {
            headers: {
                jwt: jwt_token
            },
        }).then(res => {
            if (res.data.status) {
                swal(res.data.message, {
                    icon: "success",
                    buttons: false,
                    timer: 2000
                }).then(
                    window.location.reload()
                )
            } else if (res.data.message === "name cannot be empty") {
                swal("Enter Name", {
                    icon: 'warning',
                    button: true,
                })
            } else if (res.data.message === "email cannot be empty"){
                swal("Enter Email", {
                    icon: 'warning',
                    button: true,
                })
            } else if(res.data.message === "Mobile cannot be empty") {
                swal("Enter Mobile Number", {
                    icon: 'warning',
                    button: true,
                })
            } else if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            } else {
                swal(res.data.message, {
                    icon: "warning",
                    button: true,
                })
            }
        });
    }
    function SubmitImage(e) {
        e.preventDefault();
        axios.post(base_url + 'users/update_admin_profile_picture', {
            admin_id: admin_id.id,
            profile_picture: usrProfile
        },
            {
                headers: {
                    jwt: jwt_token
                },
            })
            .then(res => {
                // console.log(res)
                if (res.data.status) {
                    swal(res.data.message, {
                        icon: "success",
                        buttons: false,
                        timer: 2000
                    }).then(
                        window.location.reload()
                    )
                } else if (res.data.code === 1001) {
                    localStorage.clear();
                    navigate("/login");
                } else {
                    swal(res.data.message, {
                        icon: "warning",
                        buttons: true,
                    })
                }
            });
    }
    function UpdateProfile(e) {
        setErrors({
            ...errors,
            [e.target.name]: validate(e.target.name, e.target.value)
        })
        setPssupdat({
            ...pssupdat,
            [e.target.name]: e.target.value
        })
    }

    function UpdateProfileto(e) {
        setErrorsto({
            ...errorsto,
            [e.target.name]: validate(e.target.name, e.target.value)
        })
        setAdminDetail({
            ...adminDetail,
            [e.target.name]: e.target.value
        })
    }
    validate = (name, value) => {
        switch (name) {
            case "username":
                if (!value || value.trim() === "") {
                    return "Name is Required";
                } else if (value.length > 20) {
                    return "Name is less than 20 characters!";
                } else {
                    return ''
                }
            case "email":
                if (!value) {
                    return "Email is Required";
                } else if (
                    !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
                ) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            case "mobile":
                if (!value) {
                    return "Mobile number is Required";
                } else {
                    return "";
                }
            case "password":
                if (!value) {
                    return "Password is Required";
                } else if (value.length < 8 || value.length > 12) {
                    return "Please fill at least 8 character";
                    // } else if (!value.match(/[a-z]/g)) {
                    //     return "Please enter at least lower character.";
                    // } else if (!value.match(/[A-Z]/g)) {
                    //     return "Please enter at least upper character.";
                    // } else if (!value.match(/[0-9]/g)) {
                    // return "Please enter at least one digit.";
                } else {
                    return "";
                }
            case "new_password":
                if (!value) {
                    return "New Password is Required";
                } else if (value.length < 8 || value.length > 12) {
                    return "Enter minimum 12 character";
                    // } else if (!value.match(/[a-z]/g)) {
                    //     return "Please enter at least lower character.";
                    // } else if (!value.match(/[A-Z]/g)) {
                    //     return "Please enter at least upper character.";
                    // } else if (!value.match(/[0-9]/g)) {
                    // return "Please enter at least one digit.";
                } else {
                    return "";
                }
            case "confirm_password":
                if (!value) {
                    return "Confirm Password is Required";
                }
            default: {
                return "";
            }
        }
    };
   
    useEffect(() => {
        AdminDetails()
    }, [])
    
    function AdminDetails(){
        axios.get(base_url + `register/get_backend_user_by_id/${logged_admin.id}`,{
            headers:{
                jwt : jwt_token
            }
        }).then(res=>{
            if (res.data.status) {
                setAdminDetail(res.data.data)
            } else if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            }
        })
    }
    function upload(e) {
        // setPercentage(0)
        S3FileUpload.uploadFile(e.target.files[0], config).then((data) => {
            // if(data !== ''){
            //     setPercentage(100);
            // }
            setUsrProfile(data.location)
            // console.log(data.location)
        }).catch((err) => {
            // console.log(err)
        })
    }
    const active = useSelector((state) => state.toggleSidebar.active);
    return (
        <>
            <Sidebar />
            <div className={active ? 'content' : 'content-mobile'}>
                <Container>
                    <h2 className='titl text-center mt-3'>Profile Update</h2>

                    <Row className='mt-3'>
                        <Col lg='4'>
                            <div className='view_img_main pt-0'>
                                <h1 className='pro_titl'>Update Password</h1>
                                <Form className='ps-2 pe-2 pt-3' onSubmit={(e) => SubmitUpdatedProfile(e)}>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                        <Col lg="12" className=' mb-3'>
                                            <label className='text-start w-100'>Current Password</label>
                                            <input className='form-control' type='password' placeholder="Current Password" onChange={(e) => UpdateProfile(e)} id="password" name="password" value={pssupdat.password} />
                                            <p className='text-danger mb-0 text-start'>{errors.password}</p>
                                        </Col>
                                        <Col className='mb-3' lg="12">
                                            <label className='text-start w-100'>New Password</label>
                                            <input className='form-control' type="password" placeholder="New Password" onChange={(e) => UpdateProfile(e)} id="new_password" name="new_password" value={pssupdat.new_password} />
                                            <p className='text-danger mb-0 text-start'>{errors.new_password}</p>
                                        </Col>
                                        <Col lg="12" className='mb-0'>
                                            <label className='text-start w-100'>Confirm password</label>
                                            <input className='form-control' type="password" placeholder="Confirm password" onChange={(e) => UpdateProfile(e)} id="confirm_password" name="confirm_password" value={pssupdat.confirm_password} />
                                            <p className='text-danger mb-0 text-start'>{errors.confirm_password}</p>
                                        </Col>
                                    </Form.Group>
                                    <Button className='btn btnsub w-100' type='submit'>Update</Button>
                                </Form>
                            </div>
                        </Col>
                        <Col lg='4'>
                            <div className='view_img_main pt-0'>
                                <h1 className='pro_titl'>Update Profile</h1>
                                <Form className='ps-2 pe-2 pt-3' onSubmit={(e) => SubmitUpdatedProfileto(e)}>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                        <Col className='mb-3' lg="12">
                                            <label className='text-start w-100'>Name</label>
                                            <input className='form-control' type="text" placeholder="Name" onChange={(e) => UpdateProfileto(e)} id="username" name="username" value={adminDetail.username} />
                                            <p className='text-danger mb-0 text-start'>{errorsto.name}</p>
                                        </Col>
                                        <Col className='mb-3' lg="12">
                                            <label className='text-start w-100'>Email</label>
                                            <input placeholder="Email" className='form-control' type="text" onChange={(e) => UpdateProfileto(e)} id="email" name="email" value={adminDetail.email} />
                                            <p className='text-danger mb-0 text-start'>{errorsto.email}</p>
                                        </Col>
                                        <Col className='mb-0' lg="12">
                                            <label className='text-start w-100'>Number</label>
                                            <input type="number" placeholder="Number" className='form-control' onChange={(e) => UpdateProfileto(e)} id="mobile" name="mobile" value={adminDetail.mobile} />
                                            <p className='text-danger mb-0 text-start'>{errorsto.mobile}</p>
                                        </Col>
                                        <Col lg={12} className='text-start mt-3'>
                                            <label>Select Country :</label>
                                            <input list='country code' className="form-control" onChange={(e) => UpdateProfileto(e)} id="country_code" name="country_code" value={adminDetail.country_code} autocomplete="off" required />
                                            <datalist id="country code" >
                                                <option value="0" >---select country---</option>
                                                <option value="+1">+1-CANADA</option>
                                                <option value="+1">+1-UNITED STATES</option>
                                                <option value="+1">+1-UNITED STATES MINOR OUTLYING ISLANDS</option>
                                                <option value="+7">+7-KAZAKHSTAN</option>
                                                <option value="+20">+20-EGYPT</option>
                                                <option value="+27">+27-SOUTH AFRICA</option>
                                                <option value="+30">+30-GREECE</option>
                                                <option value="+31">+31-NETHERLANDS</option>
                                                <option value="+32">+32-BELGIUM</option>
                                                <option value="+33">+33-FRANCE</option>
                                                <option value="+34">+34-SPAIN</option>
                                                <option value="+36">+36-HUNGARY</option>
                                                <option value="+39">+39-HOLY SEE (VATICAN CITY STATE)</option>
                                                <option value="+39">+39-ITALY</option>
                                                <option value="+40">+40-ROMANIA</option>
                                                <option value="+41">+41-SWITZERLAND</option>
                                                <option value="+43">+43-AUSTRIA</option>
                                                <option value="+44">+44-UNITED KINGDOM</option>
                                                <option value="+45">+45-DENMARK</option>
                                                <option value="+46">+46-SWEDEN</option>
                                                <option value="+47">+47-NORWAY</option>
                                                <option value="+47">+47-SVALBARD AND JAN MAYEN</option>
                                                <option value="+48">+48-POLAND</option>
                                                <option value="+49">+49-GERMANY</option>
                                                <option value="+51">+51-PERU</option>
                                                <option value="+52">+52-MEXICO</option>
                                                <option value="+53">+53-CUBA</option>
                                                <option value="+54">+54-ARGENTINA</option>
                                                <option value="+55">+55-BRAZIL</option>
                                                <option value="+56">+56-CHILE</option>
                                                <option value="+57">+57-COLOMBIA</option>
                                                <option value="+58">+58-VENEZUELA</option>
                                                <option value="+60">+60-MALAYSIA</option>
                                                <option value="+61">+61-AUSTRALIA</option>
                                                <option value="+61">+61-CHRISTMAS ISLAND</option>
                                                <option value="+62">+62-INDONESIA</option>
                                                <option value="+63">+63-PHILIPPINES</option>
                                                <option value="+64">+64-NEW ZEALAND</option>
                                                <option value="+65">+65-SINGAPORE</option>
                                                <option value="+66">+66-THAILAND</option>
                                                <option value="+70">+70-RUSSIAN FEDERATION</option>
                                                <option value="+81">+81-JAPAN</option>
                                                <option value="+82">+82-KOREA, REPUBLIC OF</option>
                                                <option value="+84">+84-VIET NAM</option>
                                                <option value="+86">+86-CHINA</option>
                                                <option value="+90">+90-TURKEY</option>
                                                <option value="+91">+91-INDIA</option>
                                                <option value="+92">+92-PAKISTAN</option>
                                                <option value="+93">+93-AFGHANISTAN</option>
                                                <option value="+94">+94-SRI LANKA</option>
                                                <option value="+95">+95-MYANMAR</option>
                                                <option value="+98">+98-IRAN, ISLAMIC REPUBLIC OF</option>
                                                <option value="+212">+212-MOROCCO</option>
                                                <option value="+212">+212-WESTERN SAHARA</option>
                                                <option value="+213">+213-ALGERIA</option>
                                                <option value="+216">+216-TUNISIA</option>
                                                <option value="+218">+218-LIBYAN ARAB JAMAHIRIYA</option>
                                                <option value="+220">+220-GAMBIA</option>
                                                <option value="+221">+221-SENEGAL</option>
                                                <option value="+222">+222-MAURITANIA</option>
                                                <option value="+223">+223-MALI</option>
                                                <option value="+224">+224-GUINEA</option>
                                                <option value="+225">+225-COTE D'IVOIRE</option>
                                                <option value="+226">+226-BURKINA FASO</option>
                                                <option value="+227">+227-NIGER</option>
                                                <option value="+228">+228-TOGO</option>
                                                <option value="+229">+229-BENIN</option>
                                                <option value="+230">+230-MAURITIUS</option>
                                                <option value="+231">+231-LIBERIA</option>
                                                <option value="+232">+232-SIERRA LEONE</option>
                                                <option value="+233">+233-GHANA</option>
                                                <option value="+234">+234-NIGERIA</option>
                                                <option value="+235">+235-CHAD</option>
                                                <option value="+236">+236-CENTRAL AFRICAN REPUBLIC</option>
                                                <option value="+237">+237-CAMEROON</option>
                                                <option value="+238">+238-CAPE VERDE</option>
                                                <option value="+239">+239-SAO TOME AND PRINCIPE</option>
                                                <option value="+240">+240-EQUATORIAL GUINEA</option>
                                                <option value="+241">+241-GABON</option>
                                                <option value="+242">+242-CONGO</option>
                                                <option value="+242">+242-CONGO, THE DEMOCRATIC REPUBLIC OF THE</option>
                                                <option value="+244">+244-ANGOLA</option>
                                                <option value="+245">+245-GUINEA-BISSAU</option>
                                                <option value="+246">+246-BRITISH INDIAN OCEAN TERRITORY</option>
                                                <option value="+248">+248-SEYCHELLES</option>
                                                <option value="+249">+249-SUDAN</option>
                                                <option value="+250">+250-RWANDA</option>
                                                <option value="+251">+251-ETHIOPIA</option>
                                                <option value="+252">+252-SOMALIA</option>
                                                <option value="+253">+253-DJIBOUTI</option>
                                                <option value="+254">+254-KENYA</option>
                                                <option value="+255">+255-TANZANIA, UNITED REPUBLIC OF</option>
                                                <option value="+256">+256-UGANDA</option>
                                                <option value="+257">+257-BURUNDI</option>
                                                <option value="+258">+258-MOZAMBIQUE</option>
                                                <option value="+260">+260-ZAMBIA</option>
                                                <option value="+261">+261-MADAGASCAR</option>
                                                <option value="+262">+262-REUNION</option>
                                                <option value="+263">+263-ZIMBABWE</option>
                                                <option value="+264">+264-NAMIBIA</option>
                                                <option value="+265">+265-MALAWI</option>
                                                <option value="+266">+266-LESOTHO</option>
                                                <option value="+267">+267-BOTSWANA</option>
                                                <option value="+268">+268-SWAZILAND</option>
                                                <option value="+269">+269-COMOROS</option>
                                                <option value="+269">+269-MAYOTTE</option>
                                                <option value="+290">+290-SAINT HELENA</option>
                                                <option value="+291">+291-ERITREA</option>
                                                <option value="+297">+297-ARUBA</option>
                                                <option value="+298">+298-FAROE ISLANDS</option>
                                                <option value="+299">+299-GREENLAND</option>
                                                <option value="+350">+350-GIBRALTAR</option>
                                                <option value="+351">+351-PORTUGAL</option>
                                                <option value="+352">+352-LUXEMBOURG</option>
                                                <option value="+353">+353-IRELAND</option>
                                                <option value="+354">+354-ICELAND</option>
                                                <option value="+355">+355-ALBANIA</option>
                                                <option value="+356">+356-MALTA</option>
                                                <option value="+357">+357-CYPRUS</option>
                                                <option value="+358">+358-FINLAND</option>
                                                <option value="+359">+359-BULGARIA</option>
                                                <option value="+370">+370-LITHUANIA</option>
                                                <option value="+371">+371-LATVIA</option>
                                                <option value="+372">+372-ESTONIA</option>
                                                <option value="+373">+373-MOLDOVA, REPUBLIC OF</option>
                                                <option value="+374">+374-ARMENIA</option>
                                                <option value="+375">+375-BELARUS</option>
                                                <option value="+376">+376-ANDORRA</option>
                                                <option value="+377">+377-MONACO</option>
                                                <option value="+378">+378-SAN MARINO</option>
                                                <option value="+380">+380-UKRAINE</option>
                                                <option value="+381">+381-SERBIA AND MONTENEGRO</option>
                                                <option value="+385">+385-CROATIA</option>
                                                <option value="+386">+386-SLOVENIA</option>
                                                <option value="+387">+387-BOSNIA AND HERZEGOVINA</option>
                                                <option value="+389">+389-MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF</option>
                                                <option value="+420">+420-CZECH REPUBLIC</option>
                                                <option value="+421">+421-SLOVAKIA</option>
                                                <option value="+423">+423-LIECHTENSTEIN</option>
                                                <option value="+500">+500-FALKLAND ISLANDS (MALVINAS)</option>
                                                <option value="+501">+501-BELIZE</option>
                                                <option value="+502">+502-GUATEMALA</option>
                                                <option value="+503">+503-EL SALVADOR</option>
                                                <option value="+504">+504-HONDURAS</option>
                                                <option value="+505">+505-NICARAGUA</option>
                                                <option value="+506">+506-COSTA RICA</option>
                                                <option value="+507">+507-PANAMA</option>
                                                <option value="+508">+508-SAINT PIERRE AND MIQUELON</option>
                                                <option value="+509">+509-HAITI</option>
                                                <option value="+590">+590-GUADELOUPE</option>
                                                <option value="+591">+591-BOLIVIA</option>
                                                <option value="+592">+592-GUYANA</option>
                                                <option value="+593">+593-ECUADOR</option>
                                                <option value="+594">+594-FRENCH GUIANA</option>
                                                <option value="+595">+595-PARAGUAY</option>
                                                <option value="+596">+596-MARTINIQUE</option>
                                                <option value="+597">+597-SURINAME</option>
                                                <option value="+598">+598-URUGUAY</option>
                                                <option value="+599">+599-NETHERLANDS ANTILLES</option>
                                                <option value="+670">+670-TIMOR-LESTE</option>
                                                <option value="+672">+672-COCOS (KEELING) ISLANDS</option>
                                                <option value="+672">+672-NORFOLK ISLAND</option>
                                                <option value="+673">+673-BRUNEI DARUSSALAM</option>
                                                <option value="+674">+674-NAURU</option>
                                                <option value="+675">+675-PAPUA NEW GUINEA</option>
                                                <option value="+676">+676-TONGA</option>
                                                <option value="+677">+677-SOLOMON ISLANDS</option>
                                                <option value="+678">+678-VANUATU</option>
                                                <option value="+679">+679-FIJI</option>
                                                <option value="+680">+680-PALAU</option>
                                                <option value="+681">+681-WALLIS AND FUTUNA</option>
                                                <option value="+682">+682-COOK ISLANDS</option>
                                                <option value="+683">+683-NIUE</option>
                                                <option value="+684">+684-SAMOA</option>
                                                <option value="+686">+686-KIRIBATI</option>
                                                <option value="+687">+687-NEW CALEDONIA</option>
                                                <option value="+688">+688-TUVALU</option>
                                                <option value="+689">+689-FRENCH POLYNESIA</option>
                                                <option value="+690">+690-TOKELAU</option>
                                                <option value="+691">+691-MICRONESIA, FEDERATED STATES OF</option>
                                                <option value="+692">+692-MARSHALL ISLANDS</option>
                                                <option value="+850">+850-KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF</option>
                                                <option value="+852">+852-HONG KONG</option>
                                                <option value="+853">+853-MACAO</option>
                                                <option value="+855">+855-CAMBODIA</option>
                                                <option value="+856">+856-LAO PEOPLE'S DEMOCRATIC REPUBLIC</option>
                                                <option value="+880">+880-BANGLADESH</option>
                                                <option value="+886">+886-TAIWAN, PROVINCE OF CHINA</option>
                                                <option value="+960">+960-MALDIVES</option>
                                                <option value="+961">+961-LEBANON</option>
                                                <option value="+962">+962-JORDAN</option>
                                                <option value="+963">+963-SYRIAN ARAB REPUBLIC</option>
                                                <option value="+964">+964-IRAQ</option>
                                                <option value="+965">+965-KUWAIT</option>
                                                <option value="+966">+966-SAUDI ARABIA</option>
                                                <option value="+967">+967-YEMEN</option>
                                                <option value="+968">+968-OMAN</option>
                                                <option value="+970">+970-PALESTINIAN TERRITORY, OCCUPIED</option>
                                                <option value="+971">+971-UNITED ARAB EMIRATES</option>
                                                <option value="+972">+972-ISRAEL</option>
                                                <option value="+973">+973-BAHRAIN</option>
                                                <option value="+974">+974-QATAR</option>
                                                <option value="+975">+975-BHUTAN</option>
                                                <option value="+976">+976-MONGOLIA</option>
                                                <option value="+977">+977-NEPAL</option>
                                                <option value="+992">+992-TAJIKISTAN</option>
                                                <option value="+994">+994-AZERBAIJAN</option>
                                                <option value="+995">+995-GEORGIA</option>
                                                <option value="+996">+996-KYRGYZSTAN</option>
                                                <option value="+998">+998-UZBEKISTAN</option>
                                                <option value="+1242">+1242-BAHAMAS</option>
                                                <option value="+1246">+1246-BARBADOS</option>
                                                <option value="+1264">+1264-ANGUILLA</option>
                                                <option value="+1268">+1268-ANTIGUA AND BARBUDA</option>
                                                <option value="+1284">+1284-VIRGIN ISLANDS, BRITISH</option>
                                                <option value="+1340">+1340-VIRGIN ISLANDS, U.S.</option>
                                                <option value="+1345">+1345-CAYMAN ISLANDS</option>
                                                <option value="+1441">+1441-BERMUDA</option>
                                                <option value="+1473">+1473-GRENADA</option>
                                                <option value="+1649">+1649-TURKS AND CAICOS ISLANDS</option>
                                                <option value="+1664">+1664-MONTSERRAT</option>
                                                <option value="+1670">+1670-NORTHERN MARIANA ISLANDS</option>
                                                <option value="+1671">+1671-GUAM</option>
                                                <option value="+1684">+1684-AMERICAN SAMOA</option>
                                                <option value="+1758">+1758-SAINT LUCIA</option>
                                                <option value="+1767">+1767-DOMINICA</option>
                                                <option value="+1784">+1784-SAINT VINCENT AND THE GRENADINES</option>
                                                <option value="+1787">+1787-PUERTO RICO</option>
                                                <option value="+1809">+1809-DOMINICAN REPUBLIC</option>
                                                <option value="+1868">+1868-TRINIDAD AND TOBAGO</option>
                                                <option value="+1869">+1869-SAINT KITTS AND NEVIS</option>
                                                <option value="+1876">+1876-JAMAICA</option>
                                                <option value="+7370">+7370-TURKMENISTAN</option>

                                            </datalist>
                                        </Col>
                                    </Form.Group>
                                    <Button className='btn btnsub w-100' type='submit'>Update</Button>
                                </Form>
                            </div>
                        </Col>
                        <Col lg='4'>
                            {/* <Loader type="spinner-cub" bgColor={"#5fcac9"} title={"Please wait while image uploading"} color={'#5fcac9'} size={50} /> */}
                            <div className='view_img_main pt-0'>
                                <h1 className='pro_titl'>Update Image</h1>
                                <Form className='ps-2 pe-2 pt-3' onSubmit={(e) => SubmitImage(e)}>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                        <Col lg="12">
                                            <input required type='file' onChange={upload} name="profile_picture" className='form-control'></input>
                                        </Col>
                                    </Form.Group>
                                    {/* <div id='progressbarContainer'>
                                        <Progressbar
                                            input={percentage}
                                            pathWidth={5}
                                            pathColor={['#5fcac9', '#5fcac9']}
                                            trailWidth={5}
                                            trailColor='#363636'
                                            textStyle={{ fill: '#363636' }}
                                        >
                                        </Progressbar>
                                    </div> */}
                                    <Button className='btn btnsub w-100' type='submit'>Update</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Profile