import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Button, Modal, Form } from "react-bootstrap"
import axios from 'axios'
import base_url from "../../api/api";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import DeleteIcon from '@material-ui/icons/Delete';
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
var totalrow = '';

function ViewGroup() {
    const [groupMemberList, setGroupMemberList] = useState([])
    const [mediaList, setMediaList] = useState([])
    const [page, setPage] = useState(1);


    const [show, setShow] = useState(false);
    const [pending, setPending] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [user, setUser] = useState({
        name: '',
        image: '',
        expiring_date: '',
        grp_join_appr_wall: '',
        is_start_now: '',
        strt_date: '',
        pin_count: '',
        likes_count: '',
        is_freeze: '',
        qr_code: '',
        key_qrcode: '',
        is_disable_qr_code: '',
        status: '',
        created_by: '',
        creation_time: '',
        update_time: '',
        _id: '',
        id: '',
    });
    const [groupMember, setGroupMember] = useState([])
    const [filGroupMember, setFilGroupMember] = useState([])
    // console.log(filGroupMember)
    const getUserWithFetch = async () => {
        const response = await fetch(base_url + 'users/get_users', {
            headers: {
                jwt: jwt_token
            }
        })
        const jsonData = await response.json();
        if (jsonData.code === 1001) {
            localStorage.clear();
            navigate("/login");
        }
        setGroupMember(jsonData.data);
        // console.log('data', response);
    }
    // function GroupMember(e) {
    //     const newgroupMember = { ...groupMember }
    //     newgroupMember[e.target.id] = e.target.value
    //     setGroupMember(newgroupMember)
    //     console.log(newgroupMember)
    // }
    // debugger
    const params = useParams();
    useEffect(() => {
        const timeout = setTimeout(() => {
            getGroupData();
            stratNow();
            getMediaList();
            getUserWithFetch();
            getGroupMemberList();
            setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);
    const navigate = useNavigate()
    const jwt_token = localStorage.getItem('token');
    const getGroupData = async () => {
        // debugger
        axios.get(base_url + `groups/get_group_detail?group_id=${params.Id}`,
            {
                headers: {
                    jwt: jwt_token
                },
            }
        ).then(res => {
            // console.log(res.data.data)
            if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            } else {
                setUser(res.data.data)
            }
        })

    };
    const getMediaList = async () => {
        axios.post(base_url + `groups/group_media_list`,
            {
                group_id: `${params.Id}`
            },
            {
                headers: {
                    jwt: jwt_token
                },
            }).then(res => {
                // console.log(res.data.data)
                if (res.data.code === 1001) {
                    localStorage.clear();
                    navigate("/login");
                } else {
                    setMediaList(res.data.data.reverse())
                }
            })
    }
    const getGroupMemberList = async () => {
        axios.post(base_url + `groups/group_member_list`,
            {
                group_id: params.Id
            },
            {
                headers: {
                    jwt: jwt_token
                },
            }).then(res => {
                // console.log(res.data.data)
                if (res.data.code === 1001) {
                    localStorage.clear();
                    navigate("/login");
                } else {
                    setGroupMemberList(res.data.data.reverse())
                }
            })
    }

    const active = useSelector((state) => state.toggleSidebar.active);
    let grp_strt_now = '';
    function stratNow() {
        if (user.is_start_now === '0') {
            return (
                grp_strt_now = "Schedule"
            )
        } else {
            return (
                grp_strt_now = "Start Now"
            )
        }
    }
    let grp_access = '';
    function GroupAccess() {
        if (user.grp_join_appr_wall === '1') {
            return (
                grp_access = "Immediate Access"
            )
        } else {
            return (
                grp_access = "Request Approval"
            )
        }
    }
    const AddAdmin = async (id, user_id, status) => {
        debugger
        axios.post(base_url + 'groups/add_group_admin', {
            group_id: params.Id,
            user_id,
            is_admin: status,
            req_id: id
        }, {
            headers: {
                jwt: jwt_token
            }
        }).then(res => {
            // console.log(res)
            if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            } else if (res.data.status === true) {
                // navigate("/user_details");
                window.location.reload()
            }
        })
        // alert(id)
    }
    const RemoveAdmin = async (id, user_id, status) => {
        debugger
        axios.post(base_url + 'groups/remove_group_admin', {
            group_id: params.Id,
            user_id,
            is_admin: status,
            req_id: id
        }, {
            headers: {
                jwt: jwt_token
            }
        }).then(res => {
            // console.log(res)
            if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            } else if (res.data.status === true) {
                // navigate("/user_details");
                window.location.reload()
            }
        })
        // alert(id)
    }
    const admin_user = JSON.parse(localStorage.getItem('user'));
    const user_role = JSON.parse(localStorage.getItem('user_role'));

    let add_grp_admin_count = 0;
    let add_grp_admin_data = false;
    let add_grp_member_count = 0;
    let add_grp_member = false;
    let delete_grp_member_count = 0;
    let delete_grp_member_data = false;
    let delete_grp_media_count = 0;
    let delete_grp_media_data = false;

    if (admin_user.is_admin === '1') {
        user_role?.map(menu_text => {
            if (menu_text.value === '10') {
                return (
                    add_grp_member_count++
                )
            }
        }, [])
        if (add_grp_member_count > 0) {
            add_grp_member = true
        }
        user_role?.map(menu_text => {
            if (menu_text.value === '9') {
                return (
                    add_grp_admin_count++
                )
            }
        }, [])
        if (add_grp_admin_count > 0) {
            add_grp_admin_data = true
        }
        user_role?.map(menu_text => {
            if (menu_text.value === '11') {
                return (
                    delete_grp_member_count++
                )
            }
        }, [])
        if (delete_grp_member_count > 0) {
            delete_grp_member_data = true
        }
        user_role?.map(menu_text => {
            if (menu_text.value === '12') {
                return (
                    delete_grp_media_count++
                )
            }
        }, [])
        if (delete_grp_media_count > 0) {
            delete_grp_media_data = true
        }
    } else if (admin_user.is_admin === '0') {
        add_grp_member = true
    }
    const actions_btn_member = (cell, row, rowIndex, formatExtraData) => {
        if (admin_user.is_admin === '1') {
            return (
                <div>
                    {delete_grp_member_data ? <DeleteIcon className='usr_dltlist' onClick={() => DeleteGroupMember(row.user_id)} /> : '--'}
                </div>
            );
        } else if(row.is_admin === '1') {
            return(
                <div>
                   {delete_grp_member_data ? <DeleteIcon className='usr_dltlist' onClick={() => DeleteGroupMember(row.user_id)} /> : "--"}
                </div>
            )
        } else {
            return (
                <div>
                    <DeleteIcon className='usr_dltlist' onClick={() => DeleteGroupMember(row.user_id)} />
                </div>
            )
        }
    };

    const StatusButton = (cell, row, rowIndex) => {
        var AdminStatus = '1';
        if (admin_user.is_admin === '1') {
            if (row.is_admin === '1') {
                AdminStatus = "0";
                return (
                    <>
                        {
                            add_grp_admin_data ? <Button className="btn btn-success" onClick={() => RemoveAdmin(row.id, row.user_id, AdminStatus)}>Admin</Button> : <p className="mb-0">Admin</p>
                        }
                    </>
                )
            } else if (row.is_admin === '0') {
                return (
                    <>
                        {add_grp_admin_data ? <Button className="btn btn-primary" onClick={() => AddAdmin(row.id, row.user_id, AdminStatus)}>Make Admin</Button> : <p className="mb-0">Member</p>}
                    </>
                )
            }
        } else {
            if (row.is_admin === '1') {
                AdminStatus = "0";
                return (
                    <Button className="btn btn-success" onClick={() => RemoveAdmin(row.id, row.user_id, AdminStatus)}>Admin</Button>

                )
            } else if (row.is_admin === '0') {
                return (
                    <Button className="btn btn-primary" onClick={() => AddAdmin(row.id, row.user_id, AdminStatus)}>Make Admin</Button>
                )
            }
        }
    }

    const groupMemberDropdown = {
        0: 'Make Admin',
        1: 'Admin'
    };

    const group_member_columns = [
        {
            text: "S.no.",
            formatter: (cell, row, rowIndex) =>rowIndex + 1
        },
        {

            text: "Name",
            sort: true,
            dataField: 'name',
            filter: textFilter()
        },
        {
            text: "Email",
            sort: true,
            dataField: 'email',
            filter: textFilter()
        },
        {
            text: "Mobile",
            sort: true,
            dataField: 'mobile',
            filter: textFilter()
        },
        {
            text: "Admin",
            sort: true,
            formatter: StatusButton,
            filter: selectFilter({
                options: groupMemberDropdown
            }),
        },
        {
            text: "Action",
            formatter: actions_btn_member
        }
    ]

    const actions_btn = (cell, row, rowIndex, formatExtraData) => {
        if (user.is_admin === '1') {
            return (
                <div>
                    {delete_grp_media_data ? <DeleteIcon className='usr_dltlist' onClick={() => DeleteMedia(row.id)} /> : "--"}
                </div>
            );
        } else {
            return (
                <div>
                    <DeleteIcon className='usr_dltlist' onClick={() => DeleteMedia(row.id)} />
                </div>
            )
        }
    };
    const media_url = (cell, row, rowIndex) => {
        return (
            <div>
                <img src={row.media_url} className="img-fluid" alt="Photo_Bomb" style={{ height: '100px' }} />
            </div>
        )
    }
    const group_media_columns = [
        {
            text: 'S.no.',
            formatter: (cell, row, rowIndex) =>rowIndex + 1
        },
        {

            text: "Image",
            formatter: media_url,
            sort: true
        },
        {
            text: "Total Likes",
            dataField: 'likes_count',
            sort: true,
            filter: textFilter()
        },
        {
            text: "Total Comment",
            dataField: "comment_count",
            sort: true,
            filter: textFilter()
        },
        {
            text: "Action",
            formatter: actions_btn

        }

    ]

    const customTotal = (from, to, size) => {
        totalrow = to
        return (
            <span className="react-bootstrap-table-pagination-total" >
                Showing {from} to {to} of {size} Results
            </span >
        )
    };
    // console.log(totalrow)
    const pagination = {
        paginationSize: 4,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        onPageChange(current) {
            setPage(current);
        },
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '25', value: 25
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }],

    };

    const DeleteMedia = async (id) => {
        swal({
            title: "Are you sure?",
            // text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal(setMediaList(mediaList.filter((item) => item.id !== id)),
                    axios.post(base_url + 'groups/delete_group_media', {
                        media_id: id,
                        group_id: user.id
                    }, {
                        headers: {
                            jwt: jwt_token
                        },
                    }).then(res => {
                        if (res.data.status) {
                            swal("Media Deleted Successfully!", {
                                icon: 'success',
                                timer: 1500
                            }).then(window.location.reload())
                        } else if (res.data.code === 1001) {
                            localStorage.clear();
                            navigate("/login");
                        }
                    }), {
                });
            }
        });
    }
    const DeleteGroupMember = async (id) => {
        swal({
            title: "Are you sure?",
            // text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal(axios.post(base_url + 'groups/remove_group_member', {
                        user_id: id,
                        group_id: user.id
                    }, {
                        headers: {
                            jwt: jwt_token
                        },
                    }).then(res => {
                        if (res.data.status) {
                            swal("Group Member Deleted Successfully!", {
                                icon: 'success',
                                timer: 1500
                            }).then(window.location.reload())
                        } else if (res.data.code === 1001) {
                            localStorage.clear();
                            navigate("/login");
                        }
                    }), {
                });
            }
        });
    }
    const [filterdata, setFilterdata] = useState({
        mobile: ''
    })
    const filter = (e) => {
        const filteruser = { ...filterdata }
        filteruser[e.target.name] = e.target.value
        setFilterdata(filteruser)
        if (filteruser.mobile.length === 10) {
            axios.get(base_url + `manual_transaction/get_manual_transaction/${parseInt(filteruser.mobile)}`, {
                headers: {
                    'Content-Type': 'application/json',
                    jwt: jwt_token
                }
            }).then(res => {
                // console.log(res.data)
                if (res.data.status === true) {
                    setFilGroupMember(res.data.data[0])
                } else if (res.data.code === 1001) {
                    localStorage.clear();
                    navigate("/login");
                } else {
                    swal('Mobile Number Not Match!', {
                        icon: 'warning',
                        button: true
                    })
                }
            })
        }
    }

    function AddGroupMember(e) {
        e.preventDefault();
        axios.post(base_url + 'groups/add_group_member', {
            group_id: user.id,
            group_users: filGroupMember.id
        },
            {
                headers: {
                    jwt: jwt_token
                },
            })
            .then(res => {
                // console.log(res.data)
                if (res.data.status === true) {
                    swal('Group Member Added Successfully!', {
                        timer: 2000,
                        buttons: false,
                        icon: 'success'
                    }).then(
                        // navigate("/group_list")
                        window.location.reload()
                    )
                } else if (res.data.code === 1001) {
                    localStorage.clear();
                    navigate("/login");
                } else {
                    swal(res.data.message, {
                        icon: 'warning',
                    })
                    // setErrMsg(res.data.message)
                }
                // <p>{errMsg}</p>
            })
    }
    const imgur = 'http://myphotobomb.com/images/user.png'

    return (
        <>
            <Sidebar />
            <div className={active ? 'content' : 'content-mobile'}>
                <Container fluid className="mt-3 mb-5">
                    <Row>
                        <Col><h4>Group Details</h4></Col>
                    </Row>
                    <Row>
                        <Col lg="3">
                            <div className="view_img pt-3 pb-3">
                                {
                                    user.image ?
                                        <img src={user.image} className="img-fluid" alt="photobomb" /> :
                                        <img src={imgur} className="img-fluid" alt="photobomb" />
                                }
                            </div>
                        </Col>
                        <Col lg="9">
                            <div className="viw_dtail">
                                <Row>
                                    <Col lg="6">
                                        <p><strong>Id : </strong >{user.id}</p>
                                    </Col>
                                    <Col lg="6">
                                        <p><strong>Name : </strong>{user.name}</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <p><strong>Expiry Date : </strong>{user.expiring_date}</p>
                                    </Col>
                                    <Col lg="6">
                                        <p><strong>User Access : </strong>{GroupAccess(grp_access)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <p><strong>Group Start : </strong>{stratNow(grp_strt_now)}</p>
                                    </Col>
                                    <Col lg="6">
                                        <p><strong>Start Date : </strong>{user.strt_date}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <p><strong>Created On : </strong>{new Intl.DateTimeFormat('en-US').format((user.creation_time * 1000))}</p>
                                    </Col>
                                    <Col lg="6">
                                        <img src={user.qr_code} className="qr_cod" />
                                        {/* <p><strong>Last Login : </strong>{user.qr_code}</p> */}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" className="mt-3"><h4>Group Member List</h4></Col>
                        <Col lg="6" className="mt-3" style={{ textAlign: 'right' }}>
                            {
                                add_grp_member ? <Button variant="primary" onClick={handleShow} className="mb-2 btn-success log_btn">
                                    Add Group Member
                                </Button> : ''
                            }

                            <Modal size="sm" show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Group Member</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form onSubmit={(e) => AddGroupMember(e)}>
                                        <Row>
                                            {/* <Col lg="6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Group Id</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Group Id"
                                                        id="group_id"
                                                        onChange={(e) => GroupMember(e)}
                                                    />
                                                </Form.Group>
                                            </Col> */}
                                            <Col lg="12">
                                                <Form.Group className="mb-3">
                                                    {/* <Form.Label>Group Users</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="User Id"
                                                        id="group_users"
                                                        onChange={(e) => GroupMember(e)}
                                                    /> */}
                                                    <input placeholder='Mobile' list="number" onChange={(e) => filter(e)} name="mobile" value={filterdata.mobile} className='form-control' type='number'>
                                                    </input>

                                                    <datalist id="number">
                                                        {groupMember?.map(item => {
                                                            return (
                                                                <>
                                                                    <option value={item.mobile}>{item.mobile}</option>
                                                                </>
                                                            )
                                                        })}

                                                    </datalist>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Button variant="primary" type="submit" className="btn-success log_btn">
                                            Add User
                                        </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={groupMemberList} columns={group_member_columns} filter={filterFactory()} />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col><h4>Comments and likes on each Image</h4></Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={mediaList} columns={group_media_columns} filter={filterFactory()} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}


export default ViewGroup;