import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Container, Accordion, Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from "../Sidebar";
import { useSelector } from "react-redux";
import base_url from "../../api/api";
import axios from "axios";
import Addplan from "./Addplan"
import swal from "sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
var totalrow = '';

const Plans = () => {
  const navigate = useNavigate()
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getUserWithFetch();
  }, []);
  const jwt_token = localStorage.getItem('token')
  const getUserWithFetch = async () => {
    axios.get(base_url + 'plans/get_user_plan', {
      headers: {
        jwt: jwt_token
      }
    }).then(res => {
      // console.log(res)
      if (res.data.status === true) {
        setUserData(res.data.data.reverse())
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      } else {
        alert(res.data.plan_name)
        setUserData(res.data.plan_name)
      }
      // <p>{errMsg}</p>
    });

  };
  const user_role = JSON.parse(localStorage.getItem('user_role'));
  const user = JSON.parse(localStorage.getItem('user'));
  let add_count = 0;
  let add_data = '';
  let edit_count = 0;
  let edit_data = false;
  let delete_count = 0;
  let delete_data = false;
  let status_count = 0;
  let status_data = false;

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '13') {
        return (
          add_count++
        )
      }
    }, [])
    if (add_count > 0) {
      add_data = <Accordion defaultActiveKey="0" className="mb-4 mt-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>ADD Plans</Accordion.Header>
          <Accordion.Body>
            <Addplan />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    }

  } else {
    add_data = <Accordion defaultActiveKey="0" className="mb-4 mt-2">
      <Accordion.Item eventKey="1">
        <Accordion.Header>ADD Plans</Accordion.Header>
        <Accordion.Body>
          <Addplan />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  }

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '14') {
        return (
          edit_count++
        )
      }
    }, [])
    if (edit_count > 0) {
      edit_data = true
    }

  }

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '15') {
        return (
          delete_count++
        )
      }
    }, [])
    if (delete_count > 0) {
      delete_data = true
    }
    user_role?.map(menu_text => {
      if (menu_text.value === '17') {
        return (
          status_count++
        )
      }
    }, [])
    if (status_count > 0) {
      status_data = true
    }
  }

  const hendleDelete = async (id) => {
    swal({
      title: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          swal('Plan Deleted Successfully!',
            setUserData(userData.filter((item) => item.id !== id)),
            axios.post(base_url + 'plans/plans_delete', {
              plan_id: `${id}`
            }, {
              headers: {
                jwt: jwt_token
              }
            }).then(res => {
              if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
              }
            })
          )
        }
      })
  }
  const active = useSelector((state) => state.toggleSidebar.active);

  const actions_btn = (cell, row, rowIndex, formatExtraData) => {
    if (user.is_admin === '1') {
      return (
        <div>

          {edit_data ?
            <Link to={"/edit_plan/" + row.id}>
              <EditIcon />
            </Link>
            : '--'
          }
          {
            delete_data ?
              <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} />
              :
              ''
          }


        </div>
      );
    } else {
      return (
        <div>
          <Link to={"/edit_plan/" + row.id}>
            <EditIcon />
          </Link>
          <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} />
        </div>
      );
    }
  };

  const UpdateGroupStatus = async (id, status) => {debugger
    axios.post(base_url + 'plans/enable_disable_plan', {
      plan_id: id,
      status
    }, {
      headers: {
        jwt: jwt_token
      },
    }).then(res => {
      if (res.data.status === true) {
        swal('Plan Status Update Successfully!', {
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(window.location.reload())
        // navigate("/user_details");
      }
    })
    // alert(id)
  }

  const StatusButton = (cell, row, rowIndex) => {
    let groupStatus = "1";
    if (user.is_admin === '1') {
      if (row.status === '1') {
        groupStatus = "0";
        return (
          <div className='usr_stats'>
            {
              status_data ? <Button className="disabl" onClick={() => UpdateGroupStatus(row.id, groupStatus)}>Disabled</Button> : <p className="mb-0">Disabled</p>
            }
          </div>
        )
      } else if (row.status === '0') {
        return (
          <div className='usr_stats'>
            {
              status_data ? <Button className="activ" onClick={() => UpdateGroupStatus(row.id, groupStatus)}>Active</Button> : <p className="mb-0">Active</p>
            }
          </div>
        )
      }
    } else {
      if (row.status === '1') {
        groupStatus = "0";
        return (
          <div className='usr_stats'>
            <Button className="disabl" onClick={() => UpdateGroupStatus(row.id, groupStatus)}>Disabled</Button>
          </div>
        )
      } else if (row.status === '0') {
        return (
          <div className='usr_stats'>
            <Button className="activ" onClick={() => UpdateGroupStatus(row.id, groupStatus)}>Active</Button>
          </div>
        )
      }
    }
  }

  const StatusDropdown = {
    1: 'Disable',
    0: 'Active'
  };

  const columns = [
    {
      text: 'S.no.',
      formatter: (cell, row, rowIndex) =>rowIndex + 1
    },
    {
      dataField: 'plan_name',
      sort: true,
      text: 'Plan Title',
      filter: textFilter()
    },
    {
      dataField: 'validity',
      sort: true,
      text: 'Validity (in days)',
      filter: textFilter()
    },
    {
      dataField: 'paid_price',
      sort: true,
      text: 'Price ($)',
      filter: textFilter()
    },
    {
      dataField: 'paid_data',
      sort: true,
      text: 'Storage',
      filter: textFilter(),
    },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      filter: selectFilter({
        options: StatusDropdown
      }),
      formatter: StatusButton
    },
    {
      dataField: 'creation_time',
      sort: true,
      text: 'Created On',
      formatter: (cell, row) => {
        let mdate = cell;
        if (typeof cell !== 'object') {
          mdate = row.creation_time;
        }
        return `${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(mdate * 1000)}`;
      },
    },
    {
      dataField: 'modified_time',
      sort: true,
      text: 'Updated On',
      formatter: (cell, row) => {
        if (row.modified_time === '') {
          return `--`
        } else {
          let dateObj = cell;
          if (typeof cell !== 'object') {
            dateObj = row.modified_time;
          }
          return `${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dateObj * 1000)}`;
        }
      },
    },
    {
      text: 'Action',
      formatter: actions_btn
    }
  ];


  // debugger
  const customTotal = (from, to, size) => {
    totalrow = to
    return (
      <span className="react-bootstrap-table-pagination-total" >
        Showing {from} to {to} of {size} Results
      </span >
    )
  };
  // console.log(totalrow)
  const pagination = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange(current) {
      setPage(current);
    },
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }],

  };

  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container fluid className="mb-5">
          {add_data}
          <h1 className='titl'>Plan List</h1>
          {/* <div>{userData.data}</div> */}
          <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={userData} columns={columns} filter={filterFactory()} />
        </Container>
      </div>
    </>
  )
}

export default Plans;

