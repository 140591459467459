import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap"
import axios from 'axios'
import base_url from "../../api/api";
import Sidebar from "../Sidebar";
import { useSelector } from "react-redux";
import swal from 'sweetalert';


function Updateplan() {
    const navigate = useNavigate();
    const [user, setUser] = useState({
        id: '',
        validity: '',
        paid_price: '',
        paid_data: '',
        plan_name: '',
    });
    function UpdateUser(e) {
        const updateuser = { ...user }
        updateuser[e.target.id] = e.target.value
        // console.log(updateuser)
        setUser(updateuser)
    }

    const jwt_token = localStorage.getItem('token')
    function SubmitUpdatedUser(e) {
        e.preventDefault();
        axios.post(base_url + 'plans/update_plan', {
            plan_id: `${user.id}`,
            plan_name: user.plan_name,
            validity: user.validity,
            paid_price: user.paid_price,
            paid_data: user.paid_data,
        }, {
            headers: {
                jwt: jwt_token
            }
        }).then(res => {
                if (res.data.status === true) {
                    navigate("/plans");
                    // window.location.reload()
                } else if (res.data.code === 1001) {
                    localStorage.clear();
                    navigate("/login");
                }
                else {
                    if (res.data.message === "plan_name cannot be empty") {
                        swal('Please Enter Plan title', {
                            icon: 'warning',
                            button: true
                        })
                    } else if (res.data.message === "validity cannot be empty") {
                        swal('Please Enter Plan Validity', {
                            icon: 'warning',
                            button: true
                        })
                    } else if (res.data.message === "paid_price cannot be empty") {
                        swal('Please Enter Price', {
                            icon: 'warning',
                            button: true
                        })
                    } else if (res.data.message === "paid_data cannot be empty") {
                        swal('Please Enter Plan data', {
                            icon: 'warning',
                            button: true
                        })
                    }
                }
                // <p>{errMsg}</p>
            })
    }
    const params = useParams();
    useEffect(() => {
        getUserWithFetch();
    }, []);
    const getUserWithFetch = async () => {
        axios.get(base_url + 'plans/plans_details?plan_id=' + params.Id, {
            headers: {
                jwt: jwt_token
            }
        }).then(res => {
            // console.log(res)
            if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            } else {
                setUser(res.data.data)
            }
        })
    }

    const active = useSelector((state) => state.toggleSidebar.active);

    return (
        <>
            <Sidebar />
            <div className={active ? 'content' : 'content-mobile'}>
                <Container fluid className="mb-5 mt-3">
                    <Row>
                        <Col><h4 className='titl mt-3'>Update Plan</h4></Col>
                    </Row>
                    <Row>
                        {/* <Col lg="3">
                            <img src={user.profile_picture} className="img-fluid" alt="photobomb" />
                        </Col> */}
                        <Col lg="12">
                            <div className='pln_updt'>
                                <form onSubmit={(e) => SubmitUpdatedUser(e)}>
                                    <Row>
                                        <Col lg={4}>
                                            <label>Id :</label>
                                            <input type='text' disabled id="id" value={user.id} className='form-control mb-3'></input>
                                        </Col>
                                        <Col lg={4}>
                                            <label>Plan Title</label>
                                            <input placeholder='Plan Name' autocomplete="off" id="plan_name" onChange={(e) => UpdateUser(e)} value={user.plan_name} className='form-control mb-3' type='text'></input>
                                        </Col>
                                        <Col lg={4}>
                                            <label>Validity (in days)</label>
                                            <input placeholder='Validity' autocomplete="off" id="validity" onChange={(e) => UpdateUser(e)} value={user.validity} className='form-control mb-3' type='number'></input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <label>Price ($)</label>
                                            <input placeholder='$' autocomplete="off" id="paid_price" onChange={(e) => UpdateUser(e)} value={user.paid_price} className='form-control mb-3' type='number'></input>
                                        </Col>
                                        <Col lg={4}>
                                            <label>Data</label>
                                            <input placeholder='Data' autocomplete="off" id="paid_data" onChange={(e) => UpdateUser(e)} value={user.paid_data} className='form-control mb-3' type='number'></input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <button type='submit' className="log_btn btn btn-success">Update</button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Updateplan