import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap"
import axios from 'axios'
import base_url from "../../api/api";
import Sidebar from "../Sidebar";
import { useSelector } from "react-redux";
import swal from 'sweetalert';



function UpdateGroup() {
    const navigate = useNavigate();
    const [user, setUser] = useState({
        name: '',
        image: '',
        expiring_date: '',
        grp_join_appr_wall: '',
        is_start_now: '',
        strt_date: '',
        pin_count: '',
        likes_count: '',
        is_freeze: '',
        qr_code: '',
        key_qrcode: '',
        is_disable_qr_code: '',
        status: '',
        created_by: '',
        creation_time: '',
        update_time: '',
        _id: '',
        id: '',
    });
    function UpdateUser(e) {
        const updateuser = { ...user }
        updateuser[e.target.id] = e.target.value
        // console.log(updateuser)
        setUser(updateuser)
    }
    function SubmitUpdatedUser(e) {
        e.preventDefault();
        axios.post(base_url + 'groups/edit_group', {
            group_id: `${user.id}`,
            name: user.name,
            expiring_date: user.expiring_date,
            grp_join_appr_wall: user.grp_join_appr_wall,
            strt_date: user.strt_date,
            is_start_now: user.is_start_now,
            image: user.image,
            created_by: user.created_by

        }, {
            headers: {
                jwt: jwt_token
            },
        })
            .then(res => {
                if (res.data.status === true) {
                    // navigate("/user_details");
                    // window.location.reload()
                    swal('Group updated successfully!', {
                        timer: 2000,
                        icon: "success",
                        button: false,
                    }).then(() => {
                        navigate("/group_list")
                    })
                }
                else {
                    // alert(res.data.message)
                    swal(res.data.message, {
                        timer: 3000,
                        icon: "warning",
                        button: false,
                    })
                }
                // <p>{errMsg}</p>
            })
    }
    const params = useParams();
    useEffect(() => {
        getUserData();
    }, []);
    const jwt_token = localStorage.getItem('token');
    const getUserData = async () => {
        axios.get(base_url + `groups/get_group_detail?group_id=${params.Id}`,
            {
                headers: {
                    jwt: jwt_token
                },
            }
        ).then(res => {
            // console.log(res.data.data)
            if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            } else {
                setUser(res.data.data)
            }
        })
    };
    const imgur = 'http://myphotobomb.com/images/user.png'
    const active = useSelector((state) => state.toggleSidebar.active);
    return (
        <>
            <Sidebar />
            <div className={active ? 'content' : 'content-mobile'}>
                <Container fluid className="mb-5 mt-3">
                    <Row>
                        <Col><h4>Update Group</h4></Col>
                    </Row>
                    <Row>
                        <Col lg="3">
                            <div className="view_img_main">
                                <div className='view_img'>
                                    {
                                        user.image ?
                                            <img src={user.image} className="img-fluid" alt="photobomb" /> :
                                            <img src={imgur} className="img-fluid" alt="photobomb" />
                                    }
                                </div>
                            </div>
                        </Col>

                        <Col lg="9">
                            <div className="view_img_main text-start ps-3 pe-3">
                                <form onSubmit={(e) => SubmitUpdatedUser(e)}>
                                    <Row>
                                        <Col lg={4}>
                                            <label>Id :</label>
                                            <input type='text' disabled id="id" value={user.id} className='form-control mb-3'></input>
                                        </Col>
                                        <Col lg={4}>
                                            <label>Name :</label>
                                            <input required placeholder='Name' id="name" onChange={(e) => UpdateUser(e)} value={user.name} className='form-control mb-3' type='text'></input>
                                        </Col>
                                        <Col lg={4}>
                                            <label>Expiry Date :</label>
                                            <input disabled placeholder='Expiry Date' id="expiring_date" onChange={(e) => UpdateUser(e)} value={user.expiring_date} className='form-control mb-3' type='text'></input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <label>User Access :</label>
                                            <Form.Select onChange={(e) => UpdateUser(e)} id="grp_join_appr_wall" value={user.grp_join_appr_wall} disabled>
                                                <option value="1">Immediate</option>
                                                <option value="0">Request Approval</option>
                                            </Form.Select>
                                        </Col>
                                        <Col lg={4}>
                                            <label>Group Start :</label>
                                            <input disabled placeholder='Group Start' id="group_start" onChange={(e) => UpdateUser(e)} value={user.strt_date} className='form-control mb-3' type='text'></input>
                                        </Col>
                                        {/* <Col lg={4}>
                                        <label>Password :</label>
                                        <input placeholder='Password' id="password" onChange={(e) => UpdateUser(e)} value={user.password} className='form-control mb-3' type='password'></input>
                                    </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <button type='submit' className="btn btnsub">Update</button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default UpdateGroup