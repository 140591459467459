import React, { useState, useEffect } from 'react'
import Sidebar from "../Sidebar";
import { Container, Form, Button, Row, Col, Accordion } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BsTags } from 'react-icons/bs'
import { BiDollar } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom'
import { FaDashcube } from 'react-icons/fa'
import base_url from "../../api/api";
import Mnultransaction from './Mnultransaction'
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
var totalrow = '';

const Atransaction = () => {

  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getUserWithFetch();
    gettransactionCount()
  }, []);
  const navigate = useNavigate()
  const jwt_token = localStorage.getItem('token')
  const getUserWithFetch = async () => {
    axios.get(`${base_url}subscription/all_payment_history`, {
      headers: {
        jwt: jwt_token
      }
    }).then(res => {
      // console.log(res)
      if (res.data.status === true) {
        // navigate("/user_details");
        //   window.location.reload()
        setUserData(res.data.data.reverse())
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      } else {
        alert(res.data.plan_name)
        setUserData(res.data.plan_name)
      }
    })

  }

  const user_role = JSON.parse(localStorage.getItem('user_role'));
  const user = JSON.parse(localStorage.getItem('user'));

  let add_count = 0;
  let add_data = '';
  let view_count = 0;
  let view_data = false;
  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '18') {
        return (
          view_count++
        )
      }
    }, [])
    if (view_count > 0) {
      view_data = true
    }
  }

  const StatusButton = (cell, row, rowIndex) => {
    if (row.status === '0') {
      return (
        <span>not found</span>
      )
    } else if (row.status === '1') {
      return (
        <span className='complet'>complete</span>
      )
    } else if (row.status === '2') {
      return (
        <span className='pending'>Pending</span>
      )
    }
  }

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '17') {
        return (
          add_count++
        )
      }
    }, [])
    if (add_count > 0) {
      add_data = <Accordion defaultActiveKey="0" className="mb-4 mt-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Manual transaction</Accordion.Header>
          <Accordion.Body>
            <Mnultransaction />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    }

  } else {
    add_data = <Accordion defaultActiveKey="0" className="mb-4 mt-2">
      <Accordion.Item eventKey="1">
        <Accordion.Header>Manual transaction</Accordion.Header>
        <Accordion.Body>
          <Mnultransaction />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  }

  const Viewbtn = (cell, row, rowIndex, formatExtraData) => {
    if (user.is_admin === '1') {
      return (
        <div>
          {view_data ?
            <Link to={'/viewtransation/' + row.user_id}>
              <button className='btn btn-danger view_btn'>View</button>
            </Link >
            : '--'
          }
        </div>
      );
    } else {
      return (
        <div>
          <Link to={'/viewtransation/' + row.user_id}>
            <button className='btn btn-danger view_btn'>View</button>
          </Link >
        </div>
      );
    }
  };

  const StatusDropdown = {
    0: 'Not Found',
    1: 'Complete',
    2: 'Pending'
  };
  const PaymntDropdown = {
    0: 'Paid',
    1: 'Free'
  };
  const columns = [
    {
      text: 'S.no.',
      formatter: (cell, row, rowIndex) =>rowIndex + 1
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      filter: textFilter()
    },
    {
      dataField: 'mobile',
      sort: true,
      text: 'Mobile',
      filter: textFilter()
    },
    {
      dataField: 'plan_name',
      sort: true,
      text: 'Plan',
      filter: textFilter()
    },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      filter: selectFilter({
        options: StatusDropdown
      }),
      formatter: StatusButton
    },
    {
      text: 'Payment Mode',
      dataField: 'payment_type',
      sort: true,
      filter: textFilter()
    },
    {
      text: 'Price',
      dataField: 'payment_price',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'creation_time',
      sort: true,
      text: 'Created On',
      formatter: (cell, row) => {
        let mdate = cell;
        if (typeof cell !== 'object') {
          mdate = row.creation_time;
        }
        return `${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(mdate*1000)}`;
      },
    },
    {
      text: 'Action',
      formatter: Viewbtn
    }
  ];


  // debugger
  const customTotal = (from, to, size) => {
    totalrow = to
    return (
      <span className="react-bootstrap-table-pagination-total" >
        Showing {from} to {to} of {size} Results
      </span >
    )
  };
  // console.log(totalrow)
  const pagination = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange(current) {
      setPage(current);
    },
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }],

  };
  const [transactionCount, setTransactionCount] = useState({})

  const gettransactionCount=()=>{
    axios.get(base_url+'subscription/get_all_transaction_count_total',{
      headers:{
        jwt : jwt_token
      }
    }).then(res=>{
      setTransactionCount(res.data.data[0])
    })
  }
  const active = useSelector((state) => state.toggleSidebar.active);
  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container>
          <Row className='mt-4 mb-4'>
            <Col md={4}>
              <div className='top_tran'>
                <Row className='justify-content-center align-items-center' >
                  <Col md={4}>
                    <div className='flt_tr fs'>
                      <FaDashcube className='icon' />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='flt_ry'>
                      <h3>{transactionCount.free_transaction}</h3>
                      <p>Free Transactions</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className='top_tran'>
                <Row className='justify-content-center align-items-center' >
                  <Col md={4}>
                    <div className='flt_tr sn'><BsTags className='icon' /></div>
                  </Col>
                  <Col md={8}>
                    <div className='flt_ry'>
                      <h3>{transactionCount.paid_trasaction}</h3>
                      <p>Paid Transactions</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className='top_tran'>
                <Row className='justify-content-center align-items-center' >
                  <Col md={4}>
                    <div className='flt_tr trd'>
                      <BiDollar className='icon' />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='flt_ry'>
                      <h3>{transactionCount.sale_amount}</h3>
                      <p>Sale amount</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {add_data}
          <div className='tr_tab '>
            <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={userData} columns={columns} filter={filterFactory()} />
          </div>
        </Container>
      </div>
    </>

  )
}

export default Atransaction