import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import base_url from "../../api/api";
import DataTable from "react-data-table-component";
import axios from 'axios';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { validate } from 'validate.js';

function Creatbacknduser() {
  const navigate = useNavigate();
  const [bkndusr, setBkndusr] = useState({
    name: '',
    email: '',
    password: '',
    select_app: '',
    country_code: '',
    mobile: '',
    role_permission: parseInt
  })
  const [errors, setErrors] = useState({
    password: ''
  })
  const jwt_token = localStorage.getItem('token')
  const [role, setRole] = useState([]);
  const rolepermisionapi = () => {
    axios.get(base_url + 'register/get_role_details', {
      headers: {
        jwt: jwt_token
      },
    }).then(res => {
      // console.log(res)
      if (res.data.status === true) {
        // console.log('success')
        setRole(res.data.data.reverse())
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      } else {
        // console.log('error')
        setRole(res.data.message)
      }
    })
  }
  useEffect(() => {
    rolepermisionapi()
  }, [])

  function submit(e) {
    e.preventDefault();
    let validationErrors = {};
    Object.keys(bkndusr).forEach(name => {
      const error = validate(name, bkndusr[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    axios.post(base_url + 'register/add_backend_user', {
      username: bkndusr.name,
      email: bkndusr.email,
      password: bkndusr.password,
      select_app: bkndusr.select_app,
      country_code : bkndusr.country_code,
      mobile: bkndusr.mobile,
      role_permission: bkndusr.role_permission
    }, {
      headers: {
        'Content-Type': 'application/json',
        jwt: jwt_token
      }
    })
      .then(res => {
        // console.log(res)
        if (res.data.status === true) {
          swal('Create Backend user successfully', {
            timer: 2000,
            buttons: false,
            icon: 'success'
          }).then(navigate('/adminlist'))
        } else if (res.data.code === 1001) {
          localStorage.clear();
          navigate("/login");
        } else {
          swal(res.data.message, {
            icon: 'warning',
            timer: 2000,
            buttons: false
          })
        }
      })
  }

  validate = (name, value) => {
    switch (name) {

      case "password":
        if (!value) {
          return "Password is Required";
        } else if (value.length < 8 || value.length > 12) {
          return "Please fill at least 8 character";
          // } else if (!value.match(/[a-z]/g)) {
          //     return "Please enter at least lower character.";
          // } else if (!value.match(/[A-Z]/g)) {
          //     return "Please enter at least upper character.";
          // } else if (!value.match(/[0-9]/g)) {
          //     return "Please enter at least one digit.";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  function User(e) {
    setErrors({
      ...errors,
      [e.target.name]: validate(e.target.name, e.target.value)
    })
    const newuser = { ...bkndusr }
    newuser[e.target.id] = e.target.value
    // console.log(newuser)
    setBkndusr(newuser);
  }
  const active = useSelector((state) => state.toggleSidebar.active);

  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container>
          <div className="com_had">
            <h3>Create Backend User</h3>
            <Form onSubmit={(e) => submit(e)}>
              <Form.Group className="mb-3">
                <Form.Label>
                  <strong>User Name</strong>
                </Form.Label>
                <input className='form-control' autocomplete="off" id='name' onChange={(e) => User(e)} value={bkndusr.name} placeholder="Enter User Name"></input>
              </Form.Group>
              <Form.Group className='text-start mt-3'>
                        <label>Select Country :</label>
                        <input list='country code' className="form-control" onChange={(e) => User(e)} id="country_code" name="country_code" value={bkndusr.country_code}  autocomplete="off" required/>
                        <datalist id="country code" >
                            <option value="0" >---select country---</option>
                            <option value="+1-CANADA">+1-CANADA</option>
                            <option value="+1-UNITED STATES">+1-UNITED STATES</option>
                            <option value="+1-UNITED STATES MINOR OUTLYING ISLANDS">+1-UNITED STATES MINOR OUTLYING ISLANDS</option>
                            <option value="+7-KAZAKHSTAN">+7-KAZAKHSTAN</option>
                            <option value="+20-EGYPT">+20-EGYPT</option>
                            <option value="+27-SOUTH AFRICA">+27-SOUTH AFRICA</option>
                            <option value="+30-GREECE">+30-GREECE</option>
                            <option value="+31-NETHERLANDS">+31-NETHERLANDS</option>
                            <option value="+32-BELGIUM">+32-BELGIUM</option>
                            <option value="+33-FRANCE">+33-FRANCE</option>
                            <option value="+34-SPAIN">+34-SPAIN</option>
                            <option value="+36-HUNGARY">+36-HUNGARY</option>
                            <option value="+39-HOLY SEE (VATICAN CITY STATE)">+39-HOLY SEE (VATICAN CITY STATE)</option>
                            <option value="+39-ITALY">+39-ITALY</option>
                            <option value="+40-ROMANIA">+40-ROMANIA</option>
                            <option value="+41-SWITZERLAND">+41-SWITZERLAND</option>
                            <option value="+43-AUSTRIA">+43-AUSTRIA</option>
                            <option value="+44-UNITED KINGDOM">+44-UNITED KINGDOM</option>
                            <option value="+45-DENMARK">+45-DENMARK</option>
                            <option value="+46-SWEDEN">+46-SWEDEN</option>
                            <option value="+47-NORWAY">+47-NORWAY</option>
                            <option value="+47-SVALBARD AND JAN MAYEN">+47-SVALBARD AND JAN MAYEN</option>
                            <option value="+48-POLAND">+48-POLAND</option>
                            <option value="+49-GERMANY">+49-GERMANY</option>
                            <option value="+51-PERU">+51-PERU</option>
                            <option value="+52-MEXICO">+52-MEXICO</option>
                            <option value="+53-CUBA">+53-CUBA</option>
                            <option value="+54-ARGENTINA">+54-ARGENTINA</option>
                            <option value="+55-BRAZIL">+55-BRAZIL</option>
                            <option value="+56-CHILE">+56-CHILE</option>
                            <option value="+57-COLOMBIA">+57-COLOMBIA</option>
                            <option value="+58-VENEZUELA">+58-VENEZUELA</option>
                            <option value="+60-MALAYSIA">+60-MALAYSIA</option>
                            <option value="+61-AUSTRALIA">+61-AUSTRALIA</option>
                            <option value="+61-CHRISTMAS ISLAND">+61-CHRISTMAS ISLAND</option>
                            <option value="+62-INDONESIA">+62-INDONESIA</option>
                            <option value="+63-PHILIPPINES">+63-PHILIPPINES</option>
                            <option value="+64-NEW ZEALAND">+64-NEW ZEALAND</option>
                            <option value="+65-SINGAPORE">+65-SINGAPORE</option>
                            <option value="+66-THAILAND">+66-THAILAND</option>
                            <option value="+70-RUSSIAN FEDERATION">+70-RUSSIAN FEDERATION</option>
                            <option value="+81-JAPAN">+81-JAPAN</option>
                            <option value="+82-KOREA, REPUBLIC OF">+82-KOREA, REPUBLIC OF</option>
                            <option value="+84-VIET NAM">+84-VIET NAM</option>
                            <option value="+86-CHINA">+86-CHINA</option>
                            <option value="+90-TURKEY">+90-TURKEY</option>
                            <option value="+91-INDIA">+91-INDIA</option>
                            <option value="+92-PAKISTAN">+92-PAKISTAN</option>
                            <option value="+93-AFGHANISTAN">+93-AFGHANISTAN</option>
                            <option value="+94-SRI LANKA">+94-SRI LANKA</option>
                            <option value="+95-MYANMAR">+95-MYANMAR</option>
                            <option value="+98-IRAN, ISLAMIC REPUBLIC OF">+98-IRAN, ISLAMIC REPUBLIC OF</option>
                            <option value="+212-MOROCCO">+212-MOROCCO</option>
                            <option value="+212-WESTERN SAHARA">+212-WESTERN SAHARA</option>
                            <option value="+213-ALGERIA">+213-ALGERIA</option>
                            <option value="+216-TUNISIA">+216-TUNISIA</option>
                            <option value="+218-LIBYAN ARAB JAMAHIRIYA">+218-LIBYAN ARAB JAMAHIRIYA</option>
                            <option value="+220-GAMBIA">+220-GAMBIA</option>
                            <option value="+221-SENEGAL">+221-SENEGAL</option>
                            <option value="+222-MAURITANIA">+222-MAURITANIA</option>
                            <option value="+223-MALI">+223-MALI</option>
                            <option value="+224-GUINEA">+224-GUINEA</option>
                            <option value="+225-COTE D'IVOIRE">+225-COTE D'IVOIRE</option>
                            <option value="+226-BURKINA FASO">+226-BURKINA FASO</option>
                            <option value="+227-NIGER">+227-NIGER</option>
                            <option value="+228-TOGO">+228-TOGO</option>
                            <option value="+229-BENIN">+229-BENIN</option>
                            <option value="+230-MAURITIUS">+230-MAURITIUS</option>
                            <option value="+231-LIBERIA">+231-LIBERIA</option>
                            <option value="+232-SIERRA LEONE">+232-SIERRA LEONE</option>
                            <option value="+233-GHANA">+233-GHANA</option>
                            <option value="+234-NIGERIA">+234-NIGERIA</option>
                            <option value="+235-CHAD">+235-CHAD</option>
                            <option value="+236-CENTRAL AFRICAN REPUBLIC">+236-CENTRAL AFRICAN REPUBLIC</option>
                            <option value="+237-CAMEROON">+237-CAMEROON</option>
                            <option value="+238-CAPE VERDE">+238-CAPE VERDE</option>
                            <option value="+239-SAO TOME AND PRINCIPE">+239-SAO TOME AND PRINCIPE</option>
                            <option value="+240-EQUATORIAL GUINEA">+240-EQUATORIAL GUINEA</option>
                            <option value="+241-GABON">+241-GABON</option>
                            <option value="+242-CONGO">+242-CONGO</option>
                            <option value="+242-CONGO, THE DEMOCRATIC REPUBLIC OF THE">+242-CONGO, THE DEMOCRATIC REPUBLIC OF THE</option>
                            <option value="+244-ANGOLA">+244-ANGOLA</option>
                            <option value="+245-GUINEA-BISSAU">+245-GUINEA-BISSAU</option>
                            <option value="+246-BRITISH INDIAN OCEAN TERRITORY">+246-BRITISH INDIAN OCEAN TERRITORY</option>
                            <option value="+248-SEYCHELLES">+248-SEYCHELLES</option>
                            <option value="+249-SUDAN">+249-SUDAN</option>
                            <option value="+250-RWANDA">+250-RWANDA</option>
                            <option value="+251-ETHIOPIA">+251-ETHIOPIA</option>
                            <option value="+252-SOMALIA">+252-SOMALIA</option>
                            <option value="+253-DJIBOUTI">+253-DJIBOUTI</option>
                            <option value="+254-KENYA">+254-KENYA</option>
                            <option value="+255-TANZANIA, UNITED REPUBLIC OF">+255-TANZANIA, UNITED REPUBLIC OF</option>
                            <option value="+256-UGANDA">+256-UGANDA</option>
                            <option value="+257-BURUNDI">+257-BURUNDI</option>
                            <option value="+258-MOZAMBIQUE">+258-MOZAMBIQUE</option>
                            <option value="+260-ZAMBIA">+260-ZAMBIA</option>
                            <option value="+261-MADAGASCAR">+261-MADAGASCAR</option>
                            <option value="+262-REUNION">+262-REUNION</option>
                            <option value="+263-ZIMBABWE">+263-ZIMBABWE</option>
                            <option value="+264-NAMIBIA">+264-NAMIBIA</option>
                            <option value="+265-MALAWI">+265-MALAWI</option>
                            <option value="+266-LESOTHO">+266-LESOTHO</option>
                            <option value="+267-BOTSWANA">+267-BOTSWANA</option>
                            <option value="+268-SWAZILAND">+268-SWAZILAND</option>
                            <option value="+269-COMOROS">+269-COMOROS</option>
                            <option value="+269-MAYOTTE">+269-MAYOTTE</option>
                            <option value="+290-SAINT HELENA">+290-SAINT HELENA</option>
                            <option value="+291-ERITREA">+291-ERITREA</option>
                            <option value="+297-ARUBA">+297-ARUBA</option>
                            <option value="+298-FAROE ISLANDS">+298-FAROE ISLANDS</option>
                            <option value="+299-GREENLAND">+299-GREENLAND</option>
                            <option value="+350-GIBRALTAR">+350-GIBRALTAR</option>
                            <option value="+351-PORTUGAL">+351-PORTUGAL</option>
                            <option value="+352-LUXEMBOURG">+352-LUXEMBOURG</option>
                            <option value="+353-IRELAND">+353-IRELAND</option>
                            <option value="+354-ICELAND">+354-ICELAND</option>
                            <option value="+355-ALBANIA">+355-ALBANIA</option>
                            <option value="+356-MALTA">+356-MALTA</option>
                            <option value="+357-CYPRUS">+357-CYPRUS</option>
                            <option value="+358-FINLAND">+358-FINLAND</option>
                            <option value="+359-BULGARIA">+359-BULGARIA</option>
                            <option value="+370-LITHUANIA">+370-LITHUANIA</option>
                            <option value="+371-LATVIA">+371-LATVIA</option>
                            <option value="+372-ESTONIA">+372-ESTONIA</option>
                            <option value="+373-MOLDOVA, REPUBLIC OF">+373-MOLDOVA, REPUBLIC OF</option>
                            <option value="+374-ARMENIA">+374-ARMENIA</option>
                            <option value="+375-BELARUS">+375-BELARUS</option>
                            <option value="+376-ANDORRA">+376-ANDORRA</option>
                            <option value="+377-MONACO">+377-MONACO</option>
                            <option value="+378">+378-SAN MARINO</option>
                            <option value="+380-UKRAINE">+380-UKRAINE</option>
                            <option value="+381-SERBIA AND MONTENEGRO">+381-SERBIA AND MONTENEGRO</option>
                            <option value="+385-CROATIA">+385-CROATIA</option>
                            <option value="+386-SLOVENI">+386-SLOVENIA</option>
                            <option value="+387-BOSNIA AND HERZEGOVINA">+387-BOSNIA AND HERZEGOVINA</option>
                            <option value="+389-MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF">+389-MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF</option>
                            <option value="+420-CZECH REPUBLIC">+420-CZECH REPUBLIC</option>
                            <option value="+421-SLOVAKIA">+421-SLOVAKIA</option>
                            <option value="+423-LIECHTENSTEIN">+423-LIECHTENSTEIN</option>
                            <option value="+500-FALKLAND ISLANDS (MALVINAS)">+500-FALKLAND ISLANDS (MALVINAS)</option>
                            <option value="+501-BELIZE">+501-BELIZE</option>
                            <option value="+502-GUATEMALA">+502-GUATEMALA</option>
                            <option value="+503-EL SALVADOR">+503-EL SALVADOR</option>
                            <option value="+504-HONDURAS">+504-HONDURAS</option>
                            <option value="+505-NICARAGUA">+505-NICARAGUA</option>
                            <option value="+506-COSTA RICA">+506-COSTA RICA</option>
                            <option value="+507-PANAMA">+507-PANAMA</option>
                            <option value="+508-SAINT PIERRE AND MIQUELON">+508-SAINT PIERRE AND MIQUELON</option>
                            <option value="+509-HAITI">+509-HAITI</option>
                            <option value="+590-GUADELOUPE">+590-GUADELOUPE</option>
                            <option value="+591-BOLIVIA">+591-BOLIVIA</option>
                            <option value="+592-GUYANA">+592-GUYANA</option>
                            <option value="+593-ECUADOR">+593-ECUADOR</option>
                            <option value="+594-FRENCH GUIANA">+594-FRENCH GUIANA</option>
                            <option value="+595-PARAGUAY">+595-PARAGUAY</option>
                            <option value="+596-MARTINIQUE">+596-MARTINIQUE</option>
                            <option value="+597-SURINAME">+597-SURINAME</option>
                            <option value="+598-URUGUAY">+598-URUGUAY</option>
                            <option value="+599-NETHERLANDS ANTILLES">+599-NETHERLANDS ANTILLES</option>
                            <option value="+670-TIMOR-LESTE">+670-TIMOR-LESTE</option>
                            <option value="+672-COCOS (KEELING) ISLANDS">+672-COCOS (KEELING) ISLANDS</option>
                            <option value="+672-NORFOLK ISLAND">+672-NORFOLK ISLAND</option>
                            <option value="+673-BRUNEI DARUSSALAM">+673-BRUNEI DARUSSALAM</option>
                            <option value="+674-NAURU">+674-NAURU</option>
                            <option value="+675-PAPUA NEW GUINEA">+675-PAPUA NEW GUINEA</option>
                            <option value="+676-TONGA">+676-TONGA</option>
                            <option value="+677-SOLOMON ISLANDS">+677-SOLOMON ISLANDS</option>
                            <option value="+678-VANUATU">+678-VANUATU</option>
                            <option value="+679-FIJI">+679-FIJI</option>
                            <option value="+680-PALAU">+680-PALAU</option>
                            <option value="+681-WALLIS AND FUTUNA">+681-WALLIS AND FUTUNA</option>
                            <option value="+682-COOK ISLANDS">+682-COOK ISLANDS</option>
                            <option value="+683-NIUE">+683-NIUE</option>
                            <option value="+684-SAMOA">+684-SAMOA</option>
                            <option value="+686-KIRIBATI">+686-KIRIBATI</option>
                            <option value="+687-NEW CALEDONIA">+687-NEW CALEDONIA</option>
                            <option value="+688-TUVALU">+688-TUVALU</option>
                            <option value="+689-FRENCH POLYNESIA">+689-FRENCH POLYNESIA</option>
                            <option value="+690-TOKELAU">+690-TOKELAU</option>
                            <option value="+691-MICRONESIA, FEDERATED STATES OF">+691-MICRONESIA, FEDERATED STATES OF</option>
                            <option value="+692-MARSHALL ISLANDS">+692-MARSHALL ISLANDS</option>
                            <option value="+850-KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF">+850-KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF</option>
                            <option value="+852-HONG KONG">+852-HONG KONG</option>
                            <option value="+853-MACAO">+853-MACAO</option>
                            <option value="+855-CAMBODIA">+855-CAMBODIA</option>
                            <option value="+856-LAO PEOPLE'S DEMOCRATIC REPUBLIC">+856-LAO PEOPLE'S DEMOCRATIC REPUBLIC</option>
                            <option value="+880-BANGLADESH">+880-BANGLADESH</option>
                            <option value="+886-TAIWAN, PROVINCE OF CHINA">+886-TAIWAN, PROVINCE OF CHINA</option>
                            <option value="+960-MALDIVES">+960-MALDIVES</option>
                            <option value="+961-LEBANON">+961-LEBANON</option>
                            <option value="+962-JORDAN">+962-JORDAN</option>
                            <option value="+963-SYRIAN ARAB REPUBLIC">+963-SYRIAN ARAB REPUBLIC</option>
                            <option value="+964-IRAQ">+964-IRAQ</option>
                            <option value="+965-KUWAIT">+965-KUWAIT</option>
                            <option value="+966-SAUDI ARABIA">+966-SAUDI ARABIA</option>
                            <option value="+967-YEMEN">+967-YEMEN</option>
                            <option value="+968-OMAN">+968-OMAN</option>
                            <option value="+970-PALESTINIAN TERRITORY, OCCUPIED">+970-PALESTINIAN TERRITORY, OCCUPIED</option>
                            <option value="+971-UNITED ARAB EMIRATES">+971-UNITED ARAB EMIRATES</option>
                            <option value="+972-ISRAEL">+972-ISRAEL</option>
                            <option value="+973-BAHRAIN">+973-BAHRAIN</option>
                            <option value="+974-QATAR">+974-QATAR</option>
                            <option value="+975-BHUTAN">+975-BHUTAN</option>
                            <option value="+976-MONGOLIA">+976-MONGOLIA</option>
                            <option value="+977-NEPAL">+977-NEPAL</option>
                            <option value="+992-TAJIKISTAN">+992-TAJIKISTAN</option>
                            <option value="+994-AZERBAIJAN">+994-AZERBAIJAN</option>
                            <option value="+995-GEORGIA">+995-GEORGIA</option>
                            <option value="+996-KYRGYZSTAN">+996-KYRGYZSTAN</option>
                            <option value="+998-UZBEKISTAN">+998-UZBEKISTAN</option>
                            <option value="+1242-BAHAMAS">+1242-BAHAMAS</option>
                            <option value="+1246-BARBADOS">+1246-BARBADOS</option>
                            <option value="+1264-ANGUILLA">+1264-ANGUILLA</option>
                            <option value="+1268-ANTIGUA AND BARBUDA">+1268-ANTIGUA AND BARBUDA</option>
                            <option value="+1284-VIRGIN ISLANDS, BRITISH">+1284-VIRGIN ISLANDS, BRITISH</option>
                            <option value="+1340-VIRGIN ISLANDS, U.S.">+1340-VIRGIN ISLANDS, U.S.</option>
                            <option value="+1345-CAYMAN ISLANDS">+1345-CAYMAN ISLANDS</option>
                            <option value="+1441-BERMUDA">+1441-BERMUDA</option>
                            <option value="+1473-GRENADA">+1473-GRENADA</option>
                            <option value="+1649-TURKS AND CAICOS ISLANDS">+1649-TURKS AND CAICOS ISLANDS</option>
                            <option value="+1664-MONTSERRAT">+1664-MONTSERRAT</option>
                            <option value="+1670-NORTHERN MARIANA ISLANDS">+1670-NORTHERN MARIANA ISLANDS</option>
                            <option value="+1671-GUAM">+1671-GUAM</option>
                            <option value="+1684-AMERICAN SAMOA">+1684-AMERICAN SAMOA</option>
                            <option value="+1758-SAINT LUCIA">+1758-SAINT LUCIA</option>
                            <option value="+1767-DOMINICA">+1767-DOMINICA</option>
                            <option value="+1784-SAINT VINCENT AND THE GRENADINES">+1784-SAINT VINCENT AND THE GRENADINES</option>
                            <option value="+1787-PUERTO RICO">+1787-PUERTO RICO</option>
                            <option value="+1809-DOMINICAN REPUBLIC">+1809-DOMINICAN REPUBLIC</option>
                            <option value="+1868-TRINIDAD AND TOBAGO">+1868-TRINIDAD AND TOBAGO</option>
                            <option value="+1869-SAINT KITTS AND NEVIS">+1869-SAINT KITTS AND NEVIS</option>
                            <option value="+1876-JAMAICA">+1876-JAMAICA</option>
                            <option value="+7370-TURKMENISTAN">+7370-TURKMENISTAN</option>

                        </datalist>
              </Form.Group>
              <Form.Group className="mb-3 mt-2">
                <Form.Label>
                  <strong>Email</strong>
                </Form.Label>
                <input className='form-control' autocomplete="off" id='email' onChange={(e) => User(e)} value={bkndusr.email} placeholder="Enter Email"></input>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <strong>Mobile</strong>
                </Form.Label>
                <input className='form-control' autocomplete="off" id='mobile' onChange={(e) => User(e)} value={bkndusr.mobile} placeholder="Enter Mobile" type='number'></input>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <strong>Password</strong>
                </Form.Label>
                <input className='form-control' autocomplete="off" id='password' onChange={(e) => User(e)} value={bkndusr.password} placeholder="Enter Password" type='password'></input>
                <p className='text-danger mb-0'>{errors.password}</p>
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Label>
                  <strong>Select App</strong>
                </Form.Label>
                <Form.Select onChange={(e) => User(e)} value={bkndusr.select_app} id='select_app'>
                  <option value="null">Please Select</option>
                  <option value="IOS">IOS</option>
                </Form.Select>
              </Form.Group> */}
              {/* <Form.Group className="mb-3">
                <Form.Label>
                  <strong>Role Permissions</strong>
                </Form.Label>
                <Form.Select onChange={(e)=> User(e)} value={bkndusr.role_permission} id='role_permission' name='role_permission'>
                    <option value="0">Role Permission</option>
                  {
                    role.map(roalin => {
                      return(
                        <>
                          <option value={roalin.id}>{roalin.role_name}</option>
                        </>
                      )
                    })
                  }
                  { <option value=''></option>}
                  
                 
                </Form.Select>
              </Form.Group> */}
              <Button className='btn btnsub ' type='submit'>Submit</Button>
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Creatbacknduser;