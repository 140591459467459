import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Form } from "react-bootstrap";
import base_url from "../../api/api";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import axios from 'axios'

function Viewtransation() {
    const [user, setUser] = useState({
        mobile: '',
        email: '',
        id: '',
        payment_id: '',
        plan_name: '',
    });
    const params = useParams();
    useEffect(() => {
        getGitHubUserWithFetch();
    }, []);
    const navigate = useNavigate()
    const jwt_token = localStorage.getItem('token')
    const getGitHubUserWithFetch = async () => {
        const response = await fetch(base_url + `manual_transaction/view_manual_transaction/${params.Id}`, {
            headers: {
                jwt: jwt_token
            }
        });
        const jsonData = await response.json();
        if (jsonData.code === 1001) {
            localStorage.clear();
            navigate("/login");
        }
        // console.log(jsonData.data)
        setUser(jsonData.data[0]);
    };
    const active = useSelector((state) => state.toggleSidebar.active);
    return (
        <>
            <Sidebar />
            <div className={active ? 'content' : 'content-mobile'}>
                <Container fluid className="mt-3 mb-5">
                    <Row>
                        <Col><h4 className='titl'>User Details</h4></Col>
                    </Row>
                    <Row>

                        <Col lg="8">
                            <div className="viw_dtail">

                                <Row>
                                    <Col lg='12'>
                                        <h3 className="pln_nm">Plan Name: {user ? user.plan_name : ''}</h3>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Pre Transaction Id</Form.Label>
                                            <Form.Control placeholder={user ? user.payment_id : ''} disabled />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Pay Via</Form.Label>
                                            <Form.Control placeholder={user.payment_type} disabled />

                                            {/* <Form.Select>
                                                <option>Free</option>
                                                <option>Cash</option>
                                            </Form.Select> */}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Transaction Status</Form.Label>
                                            <Form.Control placeholder={
                                                user.status === '1' ? 'Complete' : 'Pendding'
                                            } disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col lg='4'>
                            <div className="viw_dtail">
                                <Row>
                                    <Col lg="12">
                                        <p className="d-flex justify-content-between tr_vw"><strong>Email : </strong>{user ? user.email : ''}</p>
                                    </Col>
                                    <Col lg="12">
                                        <p className="d-flex justify-content-between tr_vw"><strong>Phone : </strong>{user ? user.mobile : ''}</p>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )

}


export default Viewtransation;