import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Col, Container, Form, Row } from 'react-bootstrap'
import base_url from '../../api/api';
import S3FileUpload from 'react-s3';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import DateTimePicker from 'react-datetime-picker';
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
    bucketName: 'photobomb-1',
    dirName: 'group_profile', /* optional */
    region: 'us-east-1',
    accessKeyId: 'AKIA2XVW5RN2BOZPCDPG',
    secretAccessKey: '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',
}

export default function AddGroup() {
    const navigate = useNavigate();
    const [grpProfile, setGrpProfile] = useState()
    const [selectedDateTime, DateTime] = useState(new Date());

    const [user, setUser] = useState({
        name: '',
        expiring_date: '48 Hours',
        grp_join_appr_wall: '0',
        strt_date: '',
        is_start_now: '1',
        image: '',
        group_admin_id: ''
    })
    let strt_now = true;
    function StartNow() {
        if (user.is_start_now === '1') {
            return (
                strt_now = true
            )
        } else if (user.is_start_now === '0') {
            return (
                strt_now = false
            )
        }
    }
    StartNow(strt_now);
    if (user.group_admin_id !== '') {
        function adminId() {
            var splitAdmin = user.group_admin_id.split(' ');
            if (splitAdmin.count == 0) {
                return null;
            }
            var grp_admin_id = splitAdmin[0];
            return grp_admin_id
        }
        var new_admin_id = adminId(user.group_admin_id)
    }
    const jwt_token = localStorage.getItem('token');
    function submit(e) {
        e.preventDefault();
        axios.post(base_url + 'groups/create_group', {
            name: user.name,
            expiring_date: user.expiring_date,
            grp_join_appr_wall: user.grp_join_appr_wall,
            strt_date: newDate,
            is_start_now: user.is_start_now,
            image: grpProfile,
            admin_id: new_admin_id
        },
            {
                headers: {
                    jwt: jwt_token
                },
            })
            .then(res => {
                // console.log(res)
                if (res.data.status === true) {
                    swal('Group Created Successfully!', {
                        timer: 1500,
                        buttons: false,
                        icon: 'success'
                    }).then(
                        // navigate("/group_list")
                        window.location.reload()
                    )
                    // window.location.reload()
                } else if (res.data.message === "name cannot be empty") {
                    swal("Enter Name", {
                        icon: 'warning',
                        button: true,
                    })
                } else if (res.data.message === "expiring_date cannot be empty") {
                    swal("Enter Group Expiry", {
                        icon: 'warning',
                        button: true,
                    })
                } else if (res.data.message === "grp_join_appr_wall cannot be empty") {
                    swal("Select User Access", {
                        icon: 'warning',
                        button: true,
                    })
                } else if (res.data.message === "is_start_now cannot be empty") {
                    swal("Select group start type", {
                        icon: 'warning',
                        button: true,
                    })
                } else if (res.data.code === 1001) {
                    localStorage.clear();
                    navigate("/login");
                }
                // <p>{errMsg}</p>
            })
    }

    function Group(e) {
        const newuser = { ...user }
        newuser[e.target.id] = e.target.value
        setUser(newuser)
        // console.log(newuser)
        // StartNow(strt_now);
    }
    function upload(e) {
        S3FileUpload.uploadFile(e.target.files[0], config)
            .then((data) => {
                setGrpProfile(data.location)
            })
            .catch((err) => {
                // console.log(err)
            })
    }

    let UserDataCondition = false
    const [userdata, setUserdata] = useState([]);
    const [userdataFree, setUserdataFree] = useState([]);
    if (user.is_start_now !== '' || user.expiring_date !== '') {
        if (user.is_start_now === '0' || user.expiring_date === 'No Expiration') {
            UserDataCondition = true
        } else if (user.is_start_now === '1' || user.expiring_date === '48 Hours') {
            UserDataCondition = false
        }
    }

    function premiumUsers(e) {
        axios.get(base_url + 'users/get_users', {
            headers: {
                jwt: jwt_token
            }
        }).then(res => {
            // console.log(res)
            if (res.data.status === true) {
                setUserdata(res.data.data)
            }
        })
    }
    function freeUsers(e) {
        axios.get(base_url + 'groups/get_user_trial_premium', {
            headers: {
                jwt: jwt_token
            }
        }).then(res => {
            if (res.data.status === true) {
                setUserdataFree(res.data.data)
            }
        })
    }

    useEffect(() => {
        premiumUsers();
        freeUsers();
    }, [])
    if (selectedDateTime !== '') {
        function convert(str) {
            var month, day, hours, minutes, seconds;
            var date = new Date(str),
                month = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            hours = ("0" + date.getHours()).slice(-2);
            minutes = ("0" + date.getMinutes()).slice(-2);
            seconds = ("0" + date.getSeconds()).slice(-2);

            var mySQLDate = [date.getFullYear(), month, day].join("-");
            var mySQLTime = [hours, minutes, seconds].join(":");
            return [mySQLDate, mySQLTime].join(" ");
        }
        if (strt_now === false) {
            var newDate = convert(selectedDateTime)
        }else if (strt_now === true) {
            var newDate = convert(new Date())
        }
    }
    return (
        <>
            <form onSubmit={(e) => submit(e)} className='group_form'>
                <Row>
                    <Col lg={3}>
                        <label>Group Name :</label>
                        <input placeholder='Name' onChange={(e) => Group(e)} id="name" value={user.name} className='form-control mb-3' type='text' autocomplete="off"></input>
                    </Col>
                    <Col lg={3}>
                        <label>Group Expiry :</label>
                        <Form.Select onChange={(e) => Group(e)} id="expiring_date" value={user.expiring_date}>
                            <option value="48 Hours">48 Hours</option>
                            <option value="No Expiration">No Expiration</option>
                        </Form.Select>
                    </Col>
                    <Col lg={3}>
                        <label>User Access :</label>
                        <Form.Select onChange={(e) => Group(e)} id="grp_join_appr_wall" value={user.grp_join_appr_wall}>
                            <option value="1">Immediate</option>
                            <option value="0">Request Approval</option>
                        </Form.Select>
                    </Col>
                    <Col lg={3}>
                        <label>Select Image :</label>
                        <input type='file' onChange={upload} id="image" className='form-control mb-3'></input>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <label>Group Start :</label>
                        <Form.Select onChange={(e) => Group(e)} id="is_start_now" value={user.is_start_now}>
                            <option value="1">Start Now</option>
                            <option value="0">Schedule</option>
                        </Form.Select>
                    </Col>
                    {
                        strt_now ? "" :
                            <Col lg={4}>
                                <label>Start Date/Time :</label>
                                {/* <input className='form-control' id="strt_date" onChange={(e) => Group(e)} value={user.strt_date} type='datetime-local' /> */}
                                <DateTimePicker className='form-control' onChange={DateTime} value={selectedDateTime} format="y-MM-dd h:mm:ss a" />
                            </Col>
                    }
                    <Col lg={3}>
                        <label>Add Admin :</label>
                        <input required autoComplete='off' className='form-control' onChange={(e) => Group(e)} list='admin' id="group_admin_id" value={user.group_admin_id} />
                        <datalist id='admin'>
                            <option value="0" >---select country---</option>
                            {
                                UserDataCondition ?
                                    userdataFree?.map(item => {
                                        return (
                                            <option value={item.id + ' ' + item.name}></option>
                                        )
                                    }) :
                                    userdata?.map(item => {
                                        return (
                                            <option value={item.id + ' ' + item.name}></option>
                                        )
                                    })
                            }

                        </datalist>

                    </Col>

                </Row>

                <Row>
                    <Col lg={12} className='mt-3'>
                        <button type='submit' className='btn btn-success log_btn'>Submit</button>
                    </Col>
                </Row>
            </form>
        </>
    )
}
