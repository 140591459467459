import React, { useState, useEffect } from 'react'
import Sidebar from "./Sidebar";
import { Container } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import S3FileUpload from 'react-s3';
import swal from 'sweetalert';
import axios from 'axios'
import base_url from '../api/api';
// import ReactHtmlParser from 'react-html-parser';

const config = {
  bucketName: 'photobomb-1',
  dirName: 'profile_images', /* optional */
  region: 'us-east-1',
  accessKeyId: 'AKIA2XVW5RN2BOZPCDPG',
  secretAccessKey: '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',
}
const Termscondition = () => {
  const [addData, showData] = useState(0);
  const [usrProfile, setUsrProfile] = useState()
  const [user, setUser] = useState({
    pagedata: '',
  });

  const jwt_token = localStorage.getItem('token')
  const [errMsg, setErrMsg] = useState()
  // console.log(usrProfile)
  const [aboutGet, setAboutGet] = useState({})
  const params = useParams();
  const navigate = useNavigate()
  const joinData = async () => {
    const responce = await fetch(base_url + `pages/get_dynamic_pages/1`, {
      headers: {
        jwt: jwt_token
      }
    });
    const jsonData = await responce.json();
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    }
    setAboutGet(jsonData.data);
  }
  useEffect(() => {
    joinData();
  }, []);
  function submit(e) {
    e.preventDefault();
    axios.post(base_url + 'pages/update_pages', {
      data: user.pagedata,
      page_id: '1',
      page_name: 'privacy & policy',
      description: `${user}`,
      type: '2',
    }, {
      headers: {
        jwt: jwt_token
      }
    })
      .then(res => {
        // console.log(res)
        if (res.data.status === true) {
          // navigate("/user_details");
          //   window.location.reload()
          swal('Data Update Successfully!', {
            icon: "success",
            buttons: false,
            timer: 2000
          })
        } else if (res.data.code === 1001) {
          localStorage.clear();
          navigate("/login");
        } else {
          alert(res.data.message)
          setErrMsg(res.data.message)
        }
        // <p>{errMsg}</p>
      })
  }

  function User(e) {
    const newuser = { ...user }
    newuser[e.target.id] = e.target.value
    setUser(newuser)
    // console.log(newuser)
  }

  const handleChange = (e, editor) => {
    setUser(editor.getData(e));
  }
  const active = useSelector((state) => state.toggleSidebar.active);
  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container>
          <h2 className='titl text-center mt-3'>Privacy & Policy</h2>
          <form onSubmit={(e) => submit(e)}>

            <CKEditor
              editor={ClassicEditor}
              onChange={(e, editor) => { handleChange(e, editor) }}
              data={aboutGet.description}
            />
            <button type="submit" className='btn btn-success mt-3 log_btn' onClick={() => showData(!addData)}>Update</button>
          </form>
          <div>
            {/* {addData ? user: ''}
          {console.log('ckeditordata:', user)} */}
          </div>
        </Container>
      </div>
    </>
  )
}

export default Termscondition