import axios from 'axios';
import React, { useState } from 'react'
import { Col, Form, FormGroup, InputGroup, Row } from 'react-bootstrap';
import base_url from '../../api/api';
import { validate } from 'validate.js';
import swal from 'sweetalert';
// import MultiSelect from 'react-multiple-select-dropdown-lite'
import Select from "react-select";
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { useNavigate } from 'react-router-dom';

export default function AddRole() {
    const [role, setRole] = useState({
        role: '',
    })
    const [subRole, setSubRole] = useState()
    const userOption = [
        { label: 'Add User', value: '1', icon:'AiFillDashboard' },
        { label: 'Edit User', value: '2', icon:'AiFillDashboard' },
        { label: 'Delete User', value: '3', icon:'AiFillDashboard' },
        { label: 'Enable/Disable User', value: '4', icon:'AiFillDashboard' },
        { label: 'Add Group', value: '5', icon:'IoMdPerson' },
        { label: 'Edit Group', value: '6', icon:'IoMdPerson' },
        { label: 'Delete Group', value: '7', icon:'IoMdPerson' },
        { label: 'Enable/Disable Group', value: '8', icon:'IoMdPerson' },
        { label: 'Add/Remove Group Admin', value: '9', icon:'IoMdPerson' },
        { label: 'Add Group Member', value: '10', icon:'IoMdPerson' },
        { label: 'Delete Group Member', value: '11', icon:'IoMdPerson' },
        { label: 'Delete Group Media', value: '12', icon:'IoMdPerson' },
        { label: 'Add Plan', value: '13', icon:'FaPaperPlane' },
        { label: 'Edit Plan', value: '14', icon:'FaPaperPlane' },
        { label: 'Delete Plan', value: '15', icon:'FaPaperPlane' },
        { label: 'Push Notification', value: '16', icon:'AiTwotoneBell' },
        { label: 'Add Transaction', value: '17', icon:'IoMdChatboxes' },
        { label: 'View Transaction', value: '18', icon:'IoMdChatboxes' },
        { label: 'Admin', value: '19'},
    ]
    const [errors, setErrors] = useState({
        name: ''
    })

    validate = (name, value) => {
        switch (name) {
            case "name":
                if (!value || value.trim() === "") {
                    return "Name is Required";
                }
        }
    }

    const jwt_token = localStorage.getItem('token')

    function Role(e) {
        setErrors({
            ...errors,
            [e.target.name]: validate(e.target.name, e.target.value)
        })
        setRole({
            ...role,
            [e.target.name]: e.target.value
        })
    }
    const navigate = useNavigate()

    function submit(e) {
        e.preventDefault();
        // if (isValid) {
        axios.post(base_url + 'register/add_role', {
            role_name: role.name,
            sub_role_id: subRole,
        }, {
            headers: {
                'Content-Type': 'application/json',
                jwt:jwt_token
            }
        }).then(res => {
            // console.log(res)
            if (res.data.status === true) {
                swal('Role Added Successfully!', {
                    icon: "success",
                    buttons: false,
                    timer: 2000
                }).then(window.location.reload())
            }else if(res.data.status === false){
                if(res.data.message === "role_name field is required" || res.data.message === "Please provide some inputs"){
                    swal('Enter Role Name!', {
                        icon: "warning",
                        button:true
                    })  
                }else if(res.data.message === "sub_role_id field is required"){
                    swal('Select Role', {
                        icon: "warning",
                        button:true
                    })  
                }
            }else if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            }
        });
    }

    function handleSelect(data) {
        setSubRole(data)
    }


    return (
        <>
            <form onSubmit={(e) => submit(e)}>
                <Row className="mb-3">
                    <Col lg={12}>
                        <label>Role Name :</label>
                        <input placeholder='Name' onChange={(e) => Role(e)} name="name" value={role.name} className='form-control' type='name'></input>
                        <p className='text-danger mb-0'>{errors.name}</p>
                    </Col>
                    <Col lg={12} className="mt-3">
                        <label>Select Roles</label>
                        <Select
                            onChange={handleSelect}
                            options={userOption}
                            // value={selectedOptions}
                            isMulti
                            id='sub_role_id'
                            isSearchable={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <button type='submit' className='btn btnsub '>Submit</button>
                    </Col>
                </Row>
            </form>
        </>
    )
}
