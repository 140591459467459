import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoMdMenu, IoMdContact } from 'react-icons/io';
import { Link } from 'react-router-dom'
import { toggleSidebar } from '../reducer/SidebarMenu'
import { NavLink } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../api/api";

const Header = () => {
    const dispatch = useDispatch();
    const active = useSelector((state) => state.toggleSidebar.active);
    const navigate = useNavigate();
    const handlelogout = () => {
        localStorage.clear();
        navigate("/login");
    };
    const [adminDetail, setAdminDetail] = useState({})
    const user = JSON.parse(localStorage.getItem('user'));
    const jwt_token = localStorage.getItem('token')
    const imgur = 'http://myphotobomb.com/images/user.png'

    useEffect(() => {
        AdminDetails()
    }, [])

    function AdminDetails() {
        axios.get(base_url + `register/get_backend_user_by_id/${user.id}`, {
            headers: {
                jwt: jwt_token
            }
        }).then(res => {
            if (res.data.status) {
                setAdminDetail(res.data.data)
            } else if (res.data.code === 1001) {
                localStorage.clear();
                navigate("/login");
            }
        })
    }
    return (
        <>
            <div className="adminheader sticky-top">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div className="adminheadermenubtn">
                            <div className='menu-icon' onClick={() => dispatch(toggleSidebar())}>

                                {!active ? <IoMdMenu className='menu' /> : <IoMdMenu className='menu' />}

                            </div>
                        </div>
                        <h1 className="titl_bm">Instashare <span className='admin'>Admin</span> </h1>
                    </div>
                    <div className="admintopright">
                        <ul className="list-unstyled mb-0">
                            {/* <Dropdown>
                                <Dropdown.Toggle variant="success" id="user_btn">
                                    <IoMdContact /> {user.username}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="drop_hover">
                                    <Dropdown.Item>Profile</Dropdown.Item>
                                    <Dropdown.Item onClick={handlelogout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>

                            </Dropdown> */}
                            <li className="dropdown show" id="user_btn">
                                <button className="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">

                                    {
                                        adminDetail.profile_picture ?
                                            <img src={adminDetail.profile_picture} className='prof_img' />
                                            :
                                            <img src={imgur} className='prof_img' />
                                    }
                                    <span className="usr_nm"> {adminDetail.username}</span>
                                </button>
                                <div className="drop_hover" aria-labelledby="dropdownMenuButton">
                                    <Link className="dropdown-item" to='/profile'>Profile</Link>
                                    <NavLink className="dropdown-item" onClick={handlelogout}>Logout</NavLink>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
