import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import { Container, Button, Accordion } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import swal from 'sweetalert';
import base_url from "../../api/api";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import AddRole from "./AddRole";
var totalrow = '';
function Rolemanagement() {
  const navigate = useNavigate()
  const [userData, setUserData] = useState([])
  const [page, setPage] = useState(1);
  const jwt_token = localStorage.getItem('token')
  function getUserWithFetch() {
    axios.get(base_url + 'register/get_role_details', {
      headers: {
        'Content-Type': 'application/json',
        jwt: jwt_token
      }
    }).then(res => {
      // console.log(res)
      if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      } else if (res.data.status) {
        setUserData(res.data.data.reverse())
      }
    })
  };

  useEffect(() => {
    getUserWithFetch()
  }, [])


  const hendleDelete = async (id) => {
    debugger
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setUserData(userData.filter((item) => item.id !== id))
          axios.get(base_url + `register/delete_role_details/` + id, {
            headers: {
              'Content-Type': 'application/json',
              jwt: jwt_token
            }
          }).then(response => {
            if (response.status) {
              swal("Role deleted sucessfully!", {
                icon: "success",
                buttons: false,
                timer: 2000
              })
            } else if (response.data.code === 1001) {
              localStorage.clear();
              navigate("/login");
            } else {
              swal("Something went wrong!", {
                icon: "warning",
                buttons: false,
                timer: 2000
              })
            }
          });

        }

      });
  }

  const active = useSelector((state) => state.toggleSidebar.active);

  // const actions_btn = (cell, row, rowIndex, formatExtraData) => {
  //   if(row.sub_role_id.value === "19") {
  //     return(
  //       <div>
  //          <div><h1>rfghjkl</h1></div>
  //     </div>
  //     )
  //   } else {
  //     return(
  //       <div>
  //       <Link to={"/edit_role/" + row.id}>
  //         <EditIcon />
  //       </Link>
  //       <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} />
  //     </div>
  //     )
  //   }
 
  // };
  const columns = [
    {
      text: 'S.no',
      formatter: (cell, row, rowIndex) =>rowIndex + 1
    },
    {
      dataField: 'role_name',
      sort: true,
      text: 'Role Name',
      filter: textFilter()
    },
    {
      // dataField: 'id',
      text: 'Action',
      formatter: (cell, row) => {
        console.log('value', row.role_name)
        if(row.role_name === "Admin") {
          
          return(
            <div>
               <div className="text-danger">Admin</div>
          </div>
          )
        } else {
          return(
            <div>
            <Link to={"/edit_role/" + row.id}>
              <EditIcon />
            </Link>
            <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} />
          </div>
          )
        }
      }
    }
  ];

  const customTotal = (from, to, size) => {
    totalrow = to
    return (
      <span className="react-bootstrap-table-pagination-total" >
        Showing {from} to {to} of {size} Results
      </span >
    )
  };
  // console.log(totalrow)
  const pagination = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange(current) {
      setPage(current);
    },
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }],

  };



  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container fluid className="mb-5">
          <Accordion defaultActiveKey="0" className="mb-4 mt-2">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Add Role</Accordion.Header>
              <Accordion.Body>
                <AddRole />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <h4 className='titl'>Role Management List </h4>
          <BootstrapTable options={{ noDataText: 'There is no data to display' }} pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={userData} columns={columns} filter={filterFactory()} />
        </Container>
      </div>
    </>
  )
}
export default Rolemanagement;