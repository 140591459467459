import React, { useState, useEffect } from 'react';
import Sidebar from "../Sidebar";
import { Container, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import base_url from "../../api/api";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MultiSelect } from "react-multi-select-component";
import 'react-multiple-select-dropdown-lite/dist/index.css'
// import MultiSelect from 'react-multiple-select-dropdown-lite'
// import 'react-multiple-select-dropdown-lite/dist/index.css'
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
var totalrow = '';
// const userddt = [{name: 'a'}, {name: 'b'}, {name: 'c'}]
const Push = () => {
  const [allOption, setAllOption] = useState([]);
  const [selected, setSelected] = useState([]);
  const [pushData, setPushData] = useState([])
  pushData.user_id = selected;
  const UserSelected = selected.map((item)=>item.value)
  // console.log(UserSelected);
    
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [checkdata, setCheckData] = useState([]);

  useEffect(() => {
    getUserWithFetch();
    getUsers();
    // setCheckData(userddt);
  }, []);
  let options = [];
  const getUsers = async () => {
    const response = await fetch(base_url + 'users/get_users', {
      headers: {
        jwt: jwt_token
      }
    });
    const jsonData = await response.json();
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    }
   
    //let selectall = {label: 'Select all', value: '*'}
    //options.push(selectall)
    jsonData.data.map(opt => {
      options.push({
        label: opt.name + ' / ' + opt.mobile + ' / ' + opt.email,
        value: opt.id,
      })
    })
    setAllOption(options);
    // console.log(options)
  };
  const jwt_token = localStorage.getItem('token');
  const getUserWithFetch = async () => {
    const response = await fetch(base_url + 'notification/get_notification', {
      headers: {
        jwt: jwt_token
      }
    });
    const jsonData = await response.json();
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    }
    setUserData(jsonData.data.reverse());

  };
  const StatusButton = (cell, row, rowIndex) => {
    if (row.post_type === 0) {
      return (
        <span>Trial</span>
      )
    } else if (row.post_type === 1) {
      return (
        <span>Free</span>
      )
    } else if (row.post_type === 2) {
      return (
        <span>Premium</span>
      )
    }
  }

  const StatusDropdown = {
    0: 'Trail',
    1: 'Free',
    2: 'Premium'
  };

  const columns = [
    {
      text: 'S.no.',
      formatter: (cell, row, rowIndex) =>rowIndex + 1
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      filter: textFilter()
    },
    {
      dataField: 'description',
      sort: true,
      text: 'Message',
      filter: textFilter()
    },
    {
      dataField: 'post_type',
      sort: true,
      text: 'Type',
      filter: selectFilter({
        options: StatusDropdown
      }),
      formatter: StatusButton
    },
    {
      dataField: 'creation_time',
      sort: true,
      text: 'Date',
      formatter: (cell, row) => {
        let dateObj = cell;
        if (typeof cell !== 'object') {
          dateObj = row.creation_time;
        }
        return `${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dateObj * 1000)}`;
      },
    },
  ];


  // debugger
  const customTotal = (from, to, size) => {
    totalrow = to
    return (
      <span className="react-bootstrap-table-pagination-total" >
        Showing {from} to {to} of {size} Results
      </span >
    )
  };
  // console.log(totalrow)
  const pagination = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange(current) {
      setPage(current);
    },
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    },],
  };

  
 

  const admin = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate()
  function submit(e) {
    e.preventDefault();
    axios.post(base_url + 'notification/send_notification', {
      user_id: UserSelected,
      title: pushData.title,
      push_subtitle: pushData.push_subtitle,
      push_body: pushData.push_body,
      admin_id: `${admin.id}`
    }, {
      headers : {
        jwt: jwt_token
      }
    }).then(res => {
      // console.log('test', res)
      if (res.data.status) {
        swal('Notification Send Successfully!', {
          timer: 1500,
          buttons: false,
          icon: 'success'
        }).then(
          window.location.reload()
        )
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      } else {
        if (res.data.message === "user_id cannot be empty"){
          swal('Select User', {
            button: true,
            icon: 'warning'
          })
        }
       else if (res.data.message === "title field is required") {
          swal('Enter notification title', {
            button: true,
            icon: 'warning'
          })
        }else if (res.data.message === "push_body field is required"){
          swal('Enter notification message', {
            button: true,
            icon: 'warning'
          })
        }
      }
    })
  }

  function push_titel(e) {
    const pushfild = { ...pushData }
    pushfild[e.target.id] = e.target.value
    setPushData(pushfild);
    // console.log(pushfild)
  }

  function handleSelect(data) {
    setSelected(data)
}
  const active = useSelector((state) => state.toggleSidebar.active);
  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container>
          <div className="com_had">
            <h3>Push Notification to users</h3>
            <Form onSubmit={(e) => submit(e)}>
              <Form.Label>
                <strong>Select Users</strong>
              </Form.Label>
             
                <MultiSelect
                  options={allOption}
                  value={selected}
                  onChange={handleSelect}
                  labelledBy="Select"
                  id= 'user_id'
                />

              <Form.Group className="mb-3 mt-2">
                <Form.Label>
                  <strong>Notification Type</strong>
                </Form.Label>
                <select onChange={(e) => push_titel(e)} id="title" value={pushData.title} className='form-control'>
                  <option value=''>Select Notification Type</option>
                  <option value='INSTASHARE'>General</option>
                  {/* <option value='Photobomb'>PHOTOBOMB </option> */}
                </select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  <strong>Type Message</strong>
                </Form.Label>
                <Form.Control as="textarea" id="push_body" onChange={(e) => push_titel(e)} rows={2} value={pushData.push_body} />
              </Form.Group>
              <Button className='btn btnsub' type='submit'>Push Notification</Button>
            </Form>
          </div>

          <div className="com_had">
            <h3>PUSH SENT LIST(s)</h3>
            <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={userData} columns={columns} filter={filterFactory()} />
          </div>
        </Container>

      </div>
    </>
  )
}





export default Push