import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Col, Container, Form, Row } from 'react-bootstrap'
import base_url from '../../api/api';
import S3FileUpload from 'react-s3';
import swal from 'sweetalert';
import validator from 'validator';
import { async, validate } from 'validate.js';
import { useNavigate } from 'react-router-dom';
window.Buffer = window.Buffer || require("buffer").Buffer;


const Mnultransaction = () => {
  const [userdata, setUserdata] = useState([]);
  const [plandata, setPlandata] = useState([{
    plan_name: '',
    validity: '',
    paid_price: '',
    paid_data: '',
    status: '',
    id: ''
  }]);

  const [mnuldata, setMnuldata] = useState({
    mobile: '',
    name: '',
    email: '',
    id: '',
    plan_data: '',
    plan_validity: '',
    payment_type: '',
    payment_price: '',
    payment_date: '',
    status: ''
  });

  const usrplandata = async () => {
    // const response = await fetch(base_url + 'plans/get_user_plan');
    // const jsonData = await response.json();
    // setPlandata(jsonData.data);
    // console.log('plan data', response);
    axios.get(base_url + 'plans/get_user_plan', {
      headers: {
        jwt: jwt_token
      }
    }).then(res => {
      // console.log(res.data)
      if (res.data.status === true) {
        setPlandata(res.data.data.reverse())
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      } else {
        setPlandata(res.data.plan_name)
      }
    })
  }
  const navigate = useNavigate()
  const getUserWithFetch = async () => {
    const response = await fetch(base_url + 'users/get_users', {
      headers: {
        jwt: jwt_token
      }
    })
    // const response = await fetch(`${base_url} users/get_users`);
    const jsonData = await response.json();
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    }
    setUserdata(jsonData.data);
    // console.log('data', response);
  }
  const [user, setUser] = useState({
    id: '',
    mobile: '',
    payment_mode: '',
    payment_id: '',
    plan_data: '',
  })

  const [errors, setErrors] = useState({
    id: '',
    mobile: '',
    payment_mode: '',
    payment_id: '',
    plan_data: '',
  });
  useEffect(() => {
    getUserWithFetch();
    usrplandata();
  }, []);

  const jwt_token = localStorage.getItem('token')
  function submit(e) {
    e.preventDefault();
    axios.post(base_url + 'manual_transaction/save_manual_transaction', {
      user_id: mnuldata.id,
      mobile: filterdata.mobile,
      payment_type: user.payment_mode,
      payment_id: user.payment_id,
      plan_data: filterPlandata.paid_data,
      email: mnuldata.email,
      plan_id: filterPlandata.id,
      plan_name: filterPlandata.plan_name,
      payment_price: filterPlandata.paid_price,
      plan_validity: filterPlandata.validity,
      
    },
      {
        headers: {
          jwt: jwt_token
        },
      }).then(res => {
        // console.log(res)
        if (res.data.status === true) {
          swal('Transaction Added Successfully!', {
            timer: 1500,
            buttons: false,
            icon: 'success'
          }).then(
            window.location.reload()
          )
        } else if (res.data.code === 1001) {
          localStorage.clear();
          navigate("/login");
        } else {
          if (res.data.message === "user_id field is required") {
            swal('Enter Mobile Number!', {
              icon: 'warning',
              button: true
            })
          } else if (res.data.message === "plan_id field is required") {
            swal('Select Plan!', {
              icon: 'warning',
              button: true
            })
          } else if (res.data.message === "payment_mode cannot be empty") {
            swal('Select Payment Mode!', {
              icon: 'warning',
              button: true
            })
          } else if (res.data.message === "payment_id cannot be empty") {
            swal('Enter Payment Id!', {
              icon: 'warning',
              button: true
            })
          }
        }
      })
  }
  const [filterdata, setFilterdata] = useState({
    mobile: ''
  })
  const filter = (e) => {
    const filteruser = { ...filterdata }
    filteruser[e.target.name] = e.target.value
    setFilterdata(filteruser)
    // console.log(filteruser.mobile)
    if (filteruser.mobile.length === 10) {
      axios.get(base_url + `manual_transaction/get_manual_transaction/${parseInt(filteruser.mobile)}`, {
        headers: {
          'Content-Type': 'application/json',
          jwt: jwt_token
        }
      }).then(res => {
        // console.log(res.data)
        if (res.data.status === true) {
          setMnuldata(res.data.data[0])
        } else if (res.data.code === 1001) {
          localStorage.clear();
          navigate("/login");
        } else {
          swal('Mobile Number Not Match!', {
            icon: 'warning',
            button: true
          })
        }
      })
    }
  }

  const [filPlandata, setfiltPlandata] = useState({})
  const [filterPlandata, setfilterPlandata] = useState({})

  const Planfilter = (e) => {
    e.preventDefault();
    const filterplan = { ...filterPlandata }
    filterplan[e.target.name] = e.target.value
    setfiltPlandata(filterplan)
    if (filterplan.plan_data.length > 3) {
      axios.post(base_url + `plans/plan_filter`, {
        plan_name: filterplan.plan_data
      },
        {
          headers: {
            jwt: jwt_token
          }
        }).then(res => {
          // console.log(res.data)
          if (res.data.status === true) {
            setfilterPlandata(res.data.data)
          } else if (res.data.code === 1001) {
            localStorage.clear();
            navigate("/login");
          } else if (res.data.message === "Plan details is not fetched.") {
            swal('No Record Found!', {
              icon: 'warning',
              button: true
            })
          }
        })
    }
  }



  function User(e) {
    const vuser = { ...user }
    vuser[e.target.name] = e.target.value
    setUser(vuser)
    // console.log(vuser);
    // setUser({
    //   ...user,
    //   [e.target.name] : e.target.value
    // })
  }
  return (
    <>
      <form onSubmit={(e) => submit(e)} >
        <Row className="mb-3">
          <Col lg={4}>
            <label>Mobile :</label>
            <input placeholder='Mobile' list="number" onChange={(e) => filter(e)} name="mobile" value={filterdata.mobile} className='form-control' type='number' autocomplete="off">
            </input>

            <datalist id="number">
              {userdata.map(item => {
                return (
                  <>
                    <option value={item.mobile}>{item.mobile}</option>
                  </>
                )
              })}

            </datalist>
            <p className='text-danger mb-0'>{errors.mobile}</p>
          </Col>
          <Col lg={4}>
            <label>Select Plan :</label>
            <input placeholder='Select Plan' autocomplete="off" list='plan list' onChange={(e) => Planfilter(e)} name="plan_data" value={filPlandata.plan_data} className='form-control' type='text'></input>
            <datalist id='plan list'>
              {plandata.map(pland => {
                return (
                  <option value={pland.plan_name}>{pland.plan_name}</option>
                )
              })}
            </datalist>
            <p className='text-danger mb-0'>{errors.name}</p>
          </Col>
          <Col lg={4}>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Payment Mode :</label>
              <Form.Select name='payment_mode' autocomplete="off" onChange={(e) => User(e)} value={user.payment_mode}>
                <option value="null">Select Payment Mode</option>
                <option value="Paid">Paid</option>
                <option value="Free">Free</option>

              </Form.Select>
              {/* <select class="form-control" id="exampleFormControlSelect1" placeholder='Payment Mode' onChange={(e) => User(e)} name="mode" value={user.payment_mode} type='text'>
                              <option>Apple Pay</option>
                              <option>Cash</option>
                            </select> */}
              <p className='text-danger mb-0'>{errors.name}</p>
            </div>
          </Col>
          <Col lg={4} className="mt-3">
            <label>Payment Id :</label>
            <input placeholder='Payment Id' autocomplete="off" onChange={(e) => User(e)} name="payment_id" value={user.payment_id} className='form-control' type='text'></input>
            <p className='text-danger mb-0'>{errors.name}</p>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <button type='submit' className='log_btn btn btn-success'>Submit</button>
          </Col>
        </Row>
      </form>
    </>
  )
}

export default Mnultransaction