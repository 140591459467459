import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import base_url from "../../api/api";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import axios from 'axios'
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { async } from "validate.js";

var totalrow = '';
function ViewUser() {
  const [page, setPage] = useState(1);
  const [lastpost, setlastpost] = useState([{
    media_type: '',
    media_url: '',
    status: '',
    likes_count: '',
    comment_count: '',
    added_by: '',
    create_date: '',
    created: '',
    modified: '',
    id: ''
  }]);
  const navigate = useNavigate()
  const jwt_token = localStorage.getItem('token')
  const fivepost = async () => {
    const response = await fetch(base_url + `groups/get_Lastfive_media/${params.Id}`,{
      headers: {
        jwt:jwt_token
      }
    });
    const jsonData = await response.json();
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    }
    setlastpost(jsonData.data)
    // console.log('five post', jsonData)
    // axios.get(base_url + `groups/get_Lastfive_media/${params.Id}`)
    // .then(res => {
    //     console.log('Res Data', res.data)
    // })
  };

  const [subprofile, setSubprofile] = useState({
    mobile: '',
    name: '',
    email: '',
    password: '',
    profile_picture: '',
    user_type: '',
    login_type: '',
    fb_id: '',
    gmail_id: '',
    apple_id: '',
    is_social: '',
    size: '',
    used_data: '',
    expire_date: '',
    givin_space: ''
  });

  const subscribeuser = async () => {
    const response = await fetch(base_url + `subscription/get_profile_subscription/${params.Id}`,{
      headers: {
        jwt:jwt_token
      }
    });
    const jsonData = await response.json();
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    }
    setSubprofile(jsonData.data);

  };

  const [user, setUser] = useState({
    country_code: '',
    mobile: '',
    name: '',
    email: '',
    profile_picture: '',
    user_type: '',
    login_type: '',
    fb_id: '',
    gmail_id: '',
    apple_id: '',
    is_social: '',
    social_type: '',
    device_type: '',
    device_token: '',
    device_id: '',
    creation_time: '',
    is_guest: '',
    last_login: '',
    otp_verification: '',
    notification_status: '',
    status: '',
    id: '',
    password: ''
  });

  const [joingroup, setJoingroup] = useState([]);

  const joinData = async () => {
    const responce = await fetch(base_url + `groups/get_user_groups/${params.Id}`,{
      headers: {
        jwt:jwt_token
      }
    });
    const jsonData = await responce.json();
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    }
    // console.log('Join group', jsonData);
    setJoingroup(jsonData.data);
  }
  const params = useParams();
  useEffect(() => {
      getGitHubUserWithFetch();
      subscribeuser();
      fivepost();
      joinData();
  }, []);
  const getGitHubUserWithFetch = async () => {
    const response = await fetch(base_url + `users/user_details?user_id=${params.Id}`,{
      headers: {
        jwt:jwt_token
      }
    });
    const jsonData = await response.json();
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    }
    setUser(jsonData.data);

  };
  const adminshow = (cell, row, rowIndex) => {
    if (row.is_admin == 1) {
      return (
        <span>Admin</span>
      )
    } else if (row.is_admin == 0) {
      return (
        <span className='complet'>Member</span>
      )
    }
  }

  //   const groupView = () => {
  //     return(
  //         <div>
  //     <Link to={'/viewtransation/' + row.mobile}>
  //      <button className='btn btn-danger view_btn'>View</button>
  //     </Link >
  //   </div>
  //     )
  //   }
  const Viewbtn = (cell, row, rowIndex) => {
    return (
      <div>
        <Link to={'/view_group/' + row.group_id}>
          {/* <VisibilityIcon /> */}
          <button className='btn btn-danger view_btn'>View</button>
        </Link >
      </div>
    )
  }
  const columns = [
    {
      text: 'S.no.',
      formatter: (cell, row, rowIndex) =>rowIndex + 1
    },
    {
      dataField: 'group_name',
      sort: true,
      text: 'Name',
      //   filter: textFilter()
    },
    {
      dataField: 'join_date',
      sort: true,
      text: 'Date of Joining',
      formatter: (cell, row) => {
        let dateObj = cell;
        if (typeof cell !== 'object') {
          dateObj = row.join_date;
        }
        return `${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dateObj * 1000)}`;
      },
      //   filter: textFilter()
    },
    {
      dataField: 'is_admin',
      sort: true,
      text: 'Type of Member',
      formatter: adminshow
      //   filter: textFilter()
    },
    {
      text: 'Action',
      formatter: Viewbtn
      //   filter: textFilter()
    },

  ];


  // debugger
  const customTotal = (from, to, size) => {
    totalrow = to
    return (
      <span className="react-bootstrap-table-pagination-total" >
        Showing {from} to {to} of {size} Results
      </span >
    )
  };
  // console.log(totalrow)
  const pagination = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange(current) {
      setPage(current);
    },
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: '20', value: 20
    }, {
      text: '100', value: 100
    }],

  };

  const imgur = 'http://myphotobomb.com/images/user.png'
  const active = useSelector((state) => state.toggleSidebar.active);

  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container fluid className="mt-3 mb-5">
          <Row>
            <Col><h4 className='titl'>User Details</h4></Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="view_img_main">
                <div className="view_img">
                  {
                    user.profile_picture ?
                      <img src={user.profile_picture} className="img-fluid" alt="photobomb" />:
                      <img src={imgur} className="img-fluid" alt="photobomb" />
                  }
                </div>
                <div className="storag">
                  <h3>Total Space: <span>{subprofile.givin_space} GB</span></h3>
                  <h3>Used Data: <span>{subprofile.used_data}</span> </h3>
                </div>
              </div>
            </Col>
            <Col lg="9">
              <div className="viw_dtail">

                <Row>
                  <Col lg="6">
                    <p><strong>Id : </strong >{user.id}</p>
                  </Col>
                  <Col lg="6">
                    <p><strong>Name : </strong>{user.name}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <p><strong>Email : </strong>{user.email}</p>
                  </Col>
                  <Col lg="6">
                    <p><strong>Phone Number : </strong>{user.country_code} {user.mobile}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <p><strong>Password : </strong>{user.password ? <strong className="pss_inc">..........</strong> : <strong className="pss_inc">..........</strong>}</p>
                  </Col>
                  {/* <Col lg="6">
                    <p><strong>Login Via : </strong> iOS</p>
                  </Col> */}
                </Row>
                <Row>
                  <Col lg="6">
                    <p><strong>Date: </strong>{new Intl.DateTimeFormat('en-US').format(user.creation_time)}</p>
                  </Col>
                  {/* <Col lg="6">
                                    <p><strong>Last Login : </strong>{user.last_login}</p>
                                </Col> */}
                </Row>
              </div>
            </Col>
          </Row>

          <Row >
            <Col lg='3'>
              <div className="post_dv text-center">
                <h3 className="post-tit mb-3 text-left">Last 5 Post</h3>
                <Row>
                  {lastpost.map(imgp => {
                    return (
                      <Col lg='6' key={imgp.id}>
                        <img src={imgp.media_url} />
                      </Col>
                    )
                  })}

                </Row>
              </div>
            </Col>
            <Col lg='9'>
              <div className="post_dv text-center">
                <h3 className="post-tit mb-3 text-left">Group Join/ Created</h3>
                <div className='tr_tab '>
                  <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={joingroup} columns={columns} filter={filterFactory()} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}


export default ViewUser;