import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Container, Button, Accordion } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from "../Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from 'sweetalert';
import base_url from "../../api/api";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import AddGroup from "./AddGroup";
var totalrow = '';

const GroupList = () => {
  const [page, setPage] = useState(1);
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    getUserWithFetch();
  }, []);
  const navigate = useNavigate()
  const getUserWithFetch = async () => {
    const response = await fetch(base_url + 'groups/get_groups', {
      headers: {
        jwt: jwt_token
      }
    });
    const jsonData = await response.json();
    // console.log(jsonData.code)
    if (jsonData.code === 1001) {
      localStorage.clear();
      navigate("/login");
    } else{
      setUserData(jsonData.data.reverse());
    }

  };
  const jwt_token = localStorage.getItem('token');
  const hendleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          swal("Group Deleted Successfully!",
            setUserData(userData.filter((item) => item.id !== id)),
            axios.post(base_url + 'groups/group_delete', {
              group_id: id
            },
              {
                headers: {
                  jwt: jwt_token
                },
              }), {
            icon: "success",
          });
        }
      });
  }

  const UpdateGroupStatus = async (id, status) => {
    axios.post(base_url + 'groups/enable_disable_group', {
      group_id: id,
      status
    }, {
      headers: {
        jwt: jwt_token
      },
    }).then(res => {
      if (res.data.status === true) {
        swal('Group Status Update Successfully!', {
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(window.location.reload())
        // navigate("/user_details");
      }
    })
    // alert(id)
  }

  const active = useSelector((state) => state.toggleSidebar.active);
  const user_role = JSON.parse(localStorage.getItem('user_role'));
  const user = JSON.parse(localStorage.getItem('user'));

  let add_count = 0;
  let add_data = '';
  let edit_count = 0;
  let edit_data = false;
  let delete_count = 0;
  let delete_data = false;
  let status_count = 0;
  let status_data = false;

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '6') {
        return (
          edit_count++
        )
      }
    }, [])
    if (edit_count > 0) {
      edit_data = true
    }
    user_role?.map(menu_text => {
      if (menu_text.value === '7') {
        return (
          delete_count++
        )
      }
    }, [])
    if (delete_count > 0) {
      delete_data = true
    }
    user_role?.map(menu_text => {
      if (menu_text.value === '8') {
        return (
          status_count++
        )
      }
    }, [])
    if (status_count > 0) {
      status_data = true
    }
  }

  if (user.is_admin === '1') {
    user_role?.map(menu_text => {
      if (menu_text.value === '5') {
        return (
          add_count++
        )
      }
    }, [])
    if (add_count > 0) {
      add_data = <Accordion defaultActiveKey="0" className="mb-4 mt-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header>ADD Group</Accordion.Header>
          <Accordion.Body>
            <AddGroup />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    }

  } else {
    add_data = <Accordion defaultActiveKey="0" className="mb-4 mt-2">
      <Accordion.Item eventKey="1">
        <Accordion.Header>ADD Group</Accordion.Header>
        <Accordion.Body>
          <AddGroup />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  }


  const actions_btn = (cell, row, rowIndex, formatExtraData) => {
    if (user.is_admin === '1') {
      return (
        <div>
          {
            edit_data ?
              <Link to={"/edit_group/" + row.id}>
                <EditIcon />
              </Link> : ''
          }
          <Link to={'/view_group/' + row.id}>
            <VisibilityIcon />
          </Link >
          {
            delete_data ? <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} /> : ''
          }
        </div>
      );
    } else {
      return (
        <div>
          <Link to={"/edit_group/" + row.id}>
            <EditIcon />
          </Link>
          <Link to={'/view_group/' + row.id}>
            <VisibilityIcon />
          </Link >
          <DeleteIcon className='usr_dltlist' onClick={() => hendleDelete(row.id)} />
        </div>
      );
    }
  }
  const StatusButton = (cell, row, rowIndex) => {
    let groupStatus = "1";
    if (user.is_admin === '1') {
      if (row.status === '1') {
        groupStatus = "0";
        return (
          <div className='usr_stats'>
            {
              status_data ? <Button className="disabl" onClick={() => UpdateGroupStatus(row.id, groupStatus)}>Disabled</Button> : <p className="mb-0">Disabled</p>
            }
          </div>
        )
      } else if (row.status === '0') {
        return (
          <div className='usr_stats'>
            {
              status_data ? <Button className="activ" onClick={() => UpdateGroupStatus(row.id, groupStatus)}>Active</Button> : <p className="mb-0">Active</p>
            }
          </div>
        )
      }
    } else {
      if (row.status === '1') {
        groupStatus = "0";
        return (
          <div className='usr_stats'>
            <Button className="disabl" onClick={() => UpdateGroupStatus(row.id, groupStatus)}>Disabled</Button>
          </div>
        )
      } else if (row.status === '0') {
        return (
          <div className='usr_stats'>
            <Button className="activ" onClick={() => UpdateGroupStatus(row.id, groupStatus)}>Active</Button>
          </div>
        )
      }
    }
  }
  const AccessType = (cell, row, rowIndex) => {
    if (row.grp_join_appr_wall === '1') {
      return (
        <span>Immediate access</span>
      )
    } else if (row.grp_join_appr_wall === '0') {
      return (
        <span>Request Approval</span>
      )
    }
  }
  const StatusDropdown = {
    1: 'Disable',
    0: 'Active'
  };
  const AccessDropdown = {
    0: 'Request Approval',
    1: 'Immediate Access',
  };
  const columns = [
    {
      text: 'S.no.',
      formatter: (cell, row, rowIndex) =>rowIndex + 1
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'strt_date',
      sort: true,
      text: 'Start Date',
      filter: textFilter()
    },
    {
      dataField: 'expiring_date',
      sort: true,
      text: 'Expiring Date',
      filter: textFilter()
    },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      filter: selectFilter({
        options: StatusDropdown
      }),
      formatter: StatusButton
    },
    {
      dataField: 'grp_join_appr_wall',
      sort: true,
      text: 'Group Type',
      filter: selectFilter({
        options: AccessDropdown
      }),
      formatter: AccessType
    },
    {
      dataField: 'creation_time',
      sort: true,
      text: 'Creation Time',
      formatter: (cell, row) => {
        let dateObj = cell;
        if (typeof cell !== 'object') {
          dateObj = row.creation_time;
        }
        return `${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format((dateObj * 1000))}`;
      },
    },
    {
      text: 'Action',
      formatter: actions_btn
    }
  ];

  // console.log('page', page)


  const customTotal = (from, to, size) => {
    totalrow = to
    return (
      <span className="react-bootstrap-table-pagination-total" >
        Showing {from} to {to} of {size} Results
      </span >
    )
  };
  // console.log(totalrow)
  const pagination = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange(current) {
      setPage(current);
    },
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }],

  };
  // console.log(page)
  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container fluid className="mb-5">
          {add_data}
          <h4 className='titl mt-3'>Group List</h4>
          <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={userData} columns={columns} filter={filterFactory()} />
        </Container>
      </div>
    </>
  )
}
export default GroupList;