import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Container, Form, Button, Modal } from 'react-bootstrap';
import base_url from "../../api/api";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import swal from 'sweetalert';
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Countertops } from "@mui/icons-material";
var totalrow = '';

function Adminlist() {
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [vdata, setVdata] = useState([{}]);
  const jwt_token = localStorage.getItem('token')
  const [role, setRole] = useState([]);
  const rolepermisionapi = () => {
    axios.get(base_url + 'register/get_role_details',
      {
        headers: {
          jwt: jwt_token
        },
      })
      .then(res => {
        // console.log(res)
        if (res.data.status === true) {
          // console.log('success')
          setRole(res.data.data.reverse())
        } else if (res.data.code === 1001) {
          localStorage.clear();
          navigate("/login");
        } else {
          // console.log('error')
          setRole(res.data.message)
        }
      })
  }
  const viewdata = () => {
    axios.get(base_url + 'register/get_admin_details', {
      headers: {
        'Content-Type': 'application/json',
        jwt: jwt_token
      }
    })
      .then(res => {
        // console.log('data fetch now', res)
        if (res.data.status === true) {
          setVdata(res.data.data.reverse())
        } else if (res.data.code === 1001) {
          localStorage.clear();
          navigate("/login");
        }
      })

  }

  //  console.log(vdata)

  useEffect(() => {
    viewdata();
  }, []);

  const StatusDropdown = {
    0: 'Disable',
    1: 'Active'
  };

  const StatusButton = (cell, row, rowIndex) => {
    let userStatus = "0";
    if (row.status === '0') {
      userStatus = "1";
      return (
        <div className='usr_stats'>
          <Button className="disabl" onClick={() => UpdateUserStatus(row.id, userStatus)}>Disabled</Button>
        </div>
      )
    } else if (row.status === '1') {
      return (
        <div className='usr_stats'>
          <Button className="activ" onClick={() => UpdateUserStatus(row.id, userStatus)}>Active</Button>
        </div>
      )
    }
  }

  const params = useParams();
  const UpdateUserStatus = async (id, status) => {
    axios.post(base_url + `register/disabled_admin`, {
      status,
      id
    }, {
      headers: {
        'Content-Type': 'application/json',
        jwt: jwt_token
      }
    }).then(res => {
      // console.log(res)
      if (res.data.status) {
        swal('Admin Status Update Successfully!', {
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(window.location.reload())
        // navigate("/user_details");
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      }
    })
    // alert(id)
  }
  useEffect(() => {
    rolepermisionapi()
  }, [])
  const RoleAssign = (e) => {
    // debugger;
    e.preventDefault();
    axios.post(base_url + `register/give_admin_role`, {
      admin_id: `${bckndUserId}`,
      role_id: parseInt(bkndusr.select_role),
  
    }, {
      headers: {
        'Content-Type': 'application/json',
        jwt: jwt_token
      }
    }).then(res => {
      if (res.data.status) {
        swal('Role Assign Successfully!', {
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(window.location.reload())
        // navigate("/user_details");
      }
      else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      }
    })
  }
  const hendleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setVdata(vdata.filter((item) => item.id !== id))
          axios.get(base_url + 'register/delete_admin_details/' + id, {
            headers: {
              'Content-Type': 'application/json',
              jwt: jwt_token
            }
          }).then(response => {
            // console.log(response)
            if (response.status) {
              swal("Admin deleted sucessfully!", {
                icon: "success",
                buttons: false,
                timer: 2000
              })
            } else if (response.data.code === 1001) {
              localStorage.clear();
              navigate("/login");
            } else {
              swal("Something went wrong!", {
                icon: "warning",
                buttons: false,
                timer: 2000
              })
            }
          });

        }

      });
  }

  const columns = [
    {

      text: 'S.no.',
      formatter: (cell, row, rowIndex) =>rowIndex + 1
    },
    {
      dataField: 'username',
      sort: true,
      text: 'User Name',
      filter: textFilter()
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      formatter: '',
      filter: textFilter()
    },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      filter: selectFilter({
        options: StatusDropdown
      }),
      formatter: StatusButton
    },
    // {
    //   dataField: '',
    //   short: true,
    //   formatter: Dadmin
    // },
    {
      dataField: 'role_name',
      text: 'User Role',
      formatter: (cell, row, rowIndex) => {
        if(row.is_admin ==='1'){
          if (row.role_name === '') {
            return (
              <div className='usr_stats'>
                {/* <Button className="activ" onClick={handleShow}>Assign Role</Button> */}
                {/* Button trigger modal */}
                <button
                  type="button"
                  className="btn btn-primary "
                  data-bs-toggle="modal"
                  data-bs-target="#AssignRole"
                  onClick={(e) => handleShow(row.id)}
                >
                  Assign Role
                </button>
              </div>
            )
          } else if (row.role_name !== '') {
            return (
              <div className='usr_stats'>
                {row.role_name}
              </div>
            )
          }
        }else{
          return (
            <div className='usr_stats'>
              <span className="text-danger">Admin</span>
            </div>
          )
        }
      },
      filter: textFilter()
    },
    {
      text: 'Action',
      formatter: (cell, row, rowIndex) => {
        if (row.email === "admin@gmail.com") {
          return (
            <div>
            </div>
          )
        } else {
          return (
            <div>
              <DeleteIcon className='usr_dltlist' onClick={(e) => hendleDelete(row.id)} />
            </div>
          )
        }
      }
    }
  ];
  const [bckndUserId, setBckndUserId] = useState({});

  const handleShow = async (id) => {
    setBckndUserId(id)
  }
  const customTotal = (from, to, size) => {
    // totalrow = to
    return (
      <span className="react-bootstrap-table-pagination-total" >
        Showing {from} to {to} of {size} Results
      </span >
    )
  };
  // console.log(totalrow)
  const pagination = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange(current) {
      setPage(current);
    },
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }],

  };
  const [bkndusr, setBkndusr] = useState({})
  function User(e) {
    const newuser = { ...bkndusr }
    newuser[e.target.id] = e.target.value
    setBkndusr(newuser);
  }

  const active = useSelector((state) => state.toggleSidebar.active);

  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container>
          <div className="com_had">
            <h3>BACKEND USER(s) LIST</h3>
          </div>
          <BootstrapTable pagination={paginationFactory(pagination)} bootstrap4 keyField='#' data={vdata} columns={columns} filter={filterFactory()} />
        </Container>
      </div>,
      <div
        className="modal fade"
        id="AssignRole"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="AssignRoleLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AssignRoleLabel">
                Assign Role
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <Form onSubmit={(e) => RoleAssign(e)}>
                <Row>
                  <Col lg="12">
                    <Form.Select onChange={(e) => User(e)} value={bkndusr.select_role} id='select_role' className="mb-3">
                      <option value="null">Role Permission</option>
                      {/* {Dadmin}
                      <option value={bkndusr.admin_status}>Admin</option> */}
                      {
                        role.map(roalin => {
                          return (
                            <>
                              <option value={roalin.id}>{roalin.role_name}</option>
                            </>
                          )
                        })
                      }
                    </Form.Select>
                  </Col>
                </Row>
                <Button variant="primary" type="submit" className="btn-success log_btn">
                  Assign Role
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Adminlist;