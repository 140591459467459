import React, { useEffect, useState } from 'react'
import Sidebar from "./Sidebar";
import { Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from 'sweetalert';
import axios from 'axios'
import base_url from '../api/api';
import { validate } from 'validate.js';

const Helpdeskreply = () => {
  const [replytext, setReplytext] = useState({
    admin_id: '',
    message_id: '',
    reply_message: '',
  });
  const [singalmsg, setSingalmsg] = useState([{
    id: '',
    message: ''
  }]);
  const Usrmsg = () => {
    axios.get(base_url + `contact/get_message_by_id/${params.Id}`, {
      headers: {
        jwt: jwt_token
      }
    }).then(res => {

      if (res.data.status === true) {
        setSingalmsg(res.data.data[0])
      }
    })
  }
  const [getreply, setGetreply] = useState({
    id: '',
    reply_message: ''
  });
  const aminReply = () => {
    axios.get(base_url + `contact/get_all_reply_by_admin/${params.Id}`, {
      headers: {
        jwt: jwt_token
      }
    }).then(res => {
      // console.log('admin replay', res)
      if (res.data.status === true) {
        setGetreply(res.data.data)
      }
    })
  }

  useEffect(() => {
    aminReply();
    Usrmsg();
  }, [])
  const admin_id = JSON.parse(localStorage.getItem('user'));
  Math.random();
  const randomNumber = Math.floor(Math.random() * 11);
  const [errors, setErrors] = useState({
    admin_id: '',
    message_id: '',
    reply_message: '',
  })
  const params = useParams();

  validate = (name, value) => {
    switch (name) {
      case "reply_message":
        if (!value || value.trim() === "") {
          return "Reply is Required";
        } else {
          return ''
        }
      default: {
        return "";
      }
    }
  };
  const jwt_token = localStorage.getItem('token')
  const navigate = useNavigate()
  function submit(e) {
    e.preventDefault();
    axios.post(base_url + `contact/save_reply`, {
      admin_id: admin_id.id,
      message_id: singalmsg.id,
      reply_message: replytext.reply_message,
    }, {
      headers: {
        jwt: jwt_token
      }
    }).then(res => {

      if (res.data.status) {
        swal('Reply Successfull!', {
          timer: 1500,
          buttons: false,
          icon: 'success',
        }).then(window.location.reload())
      } else if (res.data.code === 1001) {
        localStorage.clear();
        navigate("/login");
      } else if (res.data.message === 'reply_message cannot be empty') {
        swal('Enter Message!', {
          icon: 'warning',
          button: true
        });
      }
    })
  }


  function User(e) {
    setErrors({
      ...errors,
      [e.target.name]: validate(e.target.name, e.target.value)
    })
    setReplytext({
      ...replytext,
      [e.target.name]: e.target.value
    })
    // console.log(replytext)
  }

  const active = useSelector((state) => state.toggleSidebar.active);
  return (
    <>
      <Sidebar />
      <div className={active ? 'content' : 'content-mobile'}>
        <Container>
          <div className='repy_dv'>
            <div className='usr_msg mt-3 mb-4'>
              <h3>User Message</h3>
              <p>{singalmsg.message}</p>
            </div>
            {
              Array.isArray(getreply) ?
              <div className='usr_msg mt-3 mb-4'>
              <h3>Admin Message</h3>
              {Array.isArray(getreply)?(getreply).map(e =>{ 
                return (
                    <p>{e.reply_message?e.reply_message:''}</p>
                  )
                  }) : null}
              
            </div>: 
            ''
            }
            <h2 className='titl text-center mt-3'>Admin Reply</h2>

            <Form onSubmit={(e) => submit(e)}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                {/* <Form.Label>Example textarea</Form.Label> */}
                <Form.Control className='rounded-0' as="textarea" rows={8} name='reply_message' onChange={(e) => User(e)} value={replytext.reply_message} type='text' />
                <p className='text-danger mb-0'>{errors.reply_message}</p>
              </Form.Group>
              <Button className='btn btnsub' type='submit'>Reply</Button>
            </Form>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Helpdeskreply