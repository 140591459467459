import React, { Suspense, lazy } from 'react';
// import './component/Header.css'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.min.js';
import PublicRoute from './Utils/PublicRoutes';
import PrivateRoute from './Utils/PrivateRoutes';
import PageNotFound from './component/PageNotFound';
import UserTable from './component/user/UserTable';
import ViewUser from './component/user/ViewUser';
import Viewtransation from './component/trancaction/Viewtransaction'
import AddNewUser from './component/user/AddNewUser';
import AOS from 'aos';
import "aos/dist/aos.css";
import UpdateUser from './component/user/UpdateUser';
import GroupList from './component/groups/GroupList';
import ViewGroup from './component/groups/ViewGroup';
import UpdateGroup from './component/groups/UpdateGroup';
import AddGroup from './component/groups/AddGroup';
import VerifyOtp from './component/VerifyOtp';
import Plans from './component/plans/Plans';
import Push from './component/plans/Push';
import Updateplan from './component/plans/Updateplan';
import Atransaction from './component/trancaction/Transaction'
import Termscondition from './component/Termscondition';
import About from './component/About';
import Privacypolicy from './component/Privacypolicy';
import Helpdesk from './component/Helpdesk';
import Helpdeskreply from './component/Helpdeskreply';
import Profile from './component/Profile';
import Rolemanagement from './component/rolemanagement/Rolemanagement';
import Creatbacknduser from './component/rolemanagement/Creatbacknduser'
import Adminlist from './component/rolemanagement/Adminlist'
import EditRole from './component/rolemanagement/EditRole';
const Login = lazy(() => import('./component/Login'));
const Dashboard = lazy(() => import('./component/Dashboard'));

// import cors from 'cors';

function App() {

  AOS.init();

  return (
    <div className="App">
        <Router>
          {/* <SideBar> */}
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<PublicRoute />}>
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/forget_password" element={<VerifyOtp />} />
                </Route>

                <Route exact path="/" element={<PrivateRoute />}>
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route exact path="/user_details" element={<UserTable />} />
                  <Route exact path="/user/:Id" element={<ViewUser />} />
                  <Route exact path="/viewtransation/:Id" element={<Viewtransation />} />
                  <Route exact path="/add_user" element={<AddNewUser />} />
                  <Route exact path="/edit_user/:Id" element={<UpdateUser />} />
                  <Route exact path="/group_list" element={<GroupList />} />
                  <Route exact path="/view_group/:Id" element={<ViewGroup />} />
                  <Route exact path="/edit_group/:Id" element={<UpdateGroup />} />
                  <Route exact path="/add_group" element={<AddGroup />} />
                  <Route exact path="/plans" element={<Plans />} />
                  <Route path='/transaction' element={<Atransaction />} />
                  <Route path='/push' element={<Push />}/>
                  <Route path='/termscondition' element={<Termscondition />}/>
                  <Route path='/about' element={<About />}/>
                  <Route path='/helpdesk' element={<Helpdesk />}/>
                  <Route path='/profile' element={<Profile />}/>
                  <Route path='/helpdeskreply/:Id' element={<Helpdeskreply />}/>
                  <Route path='/privacypolicy' element={<Privacypolicy />}/>
                  <Route path="/edit_plan/:Id" element={<Updateplan />} />
                  <Route path="/edit_role/:Id" element={<EditRole />} />
                  <Route path="/edit_subAdmin/:Id" element={<EditRole />} />
                  <Route path='/rolemanagement' element={<Rolemanagement />}/>
                  <Route path='/creatbacknduser' element={<Creatbacknduser />}/>
                  <Route path='/adminlist' element={<Adminlist />}/>
                </Route>
                <Route exact path="/:pageName" element={<PageNotFound />} />
              </Routes>
            </Suspense>
          {/* </SideBar> */}

        </Router>
    </div>
  );
}

export default App;
