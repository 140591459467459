import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import '../component/404.css'

export default function PageNotFound() {
    const params = useParams();
    return (
        <>
            <section className="page_404">
                <Container fluid>
                    <Row className="p-0">
                        <Col lg={12} className="p-0">
                            <div className="text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>
                                </div>
                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        {/* {params.pageName} Page Not Found */}
                                        The page you are looking for not avaible!
                                    </h3>
                                    <Link to="/login" className="link_404">Go to Home</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}